import React, {useState} from 'react';
import "./ReplacingAccountNumberScreen.css";
import {Link} from "react-router-dom";
import axios from "axios";

const ReplacingAccountNumberScreen = ({url, account}) => {
    const [broker, setBroker] = useState("");
    const [newAccountNumber, setNewAccountNumber] = useState("");
    const [checkbox, setCheckbox] = useState(false)

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const change_account_number = async () => {
        const error_block_1 = document.querySelector(".method-text-error.input1");
        const error_block_2 = document.querySelector(".method-text-error.input2");
        const error_checkbox = document.querySelector(".method-text-error.checkbox-error");
        const notification_text = document.querySelector(".success-notification");

        let hasErrors = false;

        if (!broker) {
            error_block_1.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_1) {
            error_block_1.innerText = "";
        }

        if (newAccountNumber.length < 6) {
            error_block_2.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_2) {
            error_block_2.innerText = "";
        }

        // Проверка чекбокса
        if (!checkbox) {
            error_checkbox.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_checkbox.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/account/change-account-number`, {
                    broker: broker,
                    new_account_number: newAccountNumber,
                    account_number: account.account_number,
                }, {withCredentials: true});
                if (response.status === 200) {
                    notification_text.innerText = response.data.detail;
                }
            } catch (error_response) {
                error_checkbox.innerText = error_response ? error_response.response.data.detail : "Неизвестная ошибка.";
                console.error('Error changing account number', error_response);
            }
        }
    };

    return (
        <>
            <div className="replacing-account-number-column">
                <div className="method">
                    <span className="method-text">Брокер</span>
                    <div className="method-input-block-base">
                        <div className="method-input-block" onClick={open_options}>
                                <span
                                    className="method-input-placeholder">{broker.length > 0 ? broker : "Выберите брокера"}</span>
                            <i className="fa fa-chevron-down"></i>
                        </div>
                        <div className="method-options-block">
                            <ul className="method-options-list">
                                <li className="method-options-item"
                                    onClick={() => {
                                        setBroker("Roboforex");
                                        open_options();
                                    }}>Roboforex
                                </li>
                                <li className="method-options-item" onClick={() => {
                                    setBroker("Forex4You");
                                    open_options();
                                }}>Forex4You
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span className="method-text-error input1"></span>
                </div>
                <div className="wallet-address">
                <span className="method-text">Новый номер счёта</span>
                    <input onChange={(event) => setNewAccountNumber(event.target.value)} type="text"
                           placeholder="Введите номер торгового счёта"
                           className="method-input-block-base"/>
                    <span className="method-text-error input2"></span>
                </div>
                <div className="checkbox-block">
                    <div className="checkbox-block-row">
                        <input type="checkbox" className="checkbox" checked={checkbox}
                               onChange={(event) => setCheckbox(event.target.checked)}/>
                        <span className="checkbox-text">Я принимаю <Link to="#" className="invite-friend-link">условия замены</Link> торгового счёта.</span>
                    </div>
                    <span className="method-text-error checkbox-error"></span>
                    <span className="success-notification" style={{color: "green", fontWeight: "800"}}></span>
                </div>
                <button className="method-of-replenishment-btn" onClick={change_account_number}>Сменить</button>
            </div>
        </>
    )
}

export default ReplacingAccountNumberScreen;