import React, {useEffect, useState} from "react";
import Notification from "../../components/Notification/Notification";
import {Link} from "react-router-dom";
import axios from "axios";

const Copying = ({setLocation, url}) => {
    const [copyingData, setCopyingData] = useState(null);
    const [loading, setLoading] = useState(false);
    const error_text = document.querySelector(".error");
    const [broker, setBroker] = useState("Roboforex");
    const [accountNumber, setAccountNumber] = useState("");
    const [checkbox, setCheckbox] = useState(false)

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const create_copying = async () => {
        let hasErrors = false;

        if (accountNumber.length < 6) {
            error_text.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_text) {
            error_text.innerText = "";
        }

        // Проверка чекбокса
        if (!checkbox) {
            error_text.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_text.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/copying/create-copying?account_number=${accountNumber}`,
                    {},
                    {withCredentials: true});
                if (response.status === 200) {
                    window.location.reload();
                }
            } catch (error_response) {
                console.error('Error creating copying', error_response);
            }
        }
    };

    const get_copying_data = async () => {
            try {
                const response = await axios.get(`${url}/copying/get-pending-copying`, {withCredentials: true})
                setCopyingData(response.data);
                setLoading(true);
            } catch (error_response) {
                console.error('Error fetching copying data', error_response);
            }
        }

    const cancel_copying = async (copying_id) => {
        try {
            const response = await axios.post(`${url}/copying/cancel-copying?copying_id=${copying_id}`,
                {},
                {withCredentials: true})
            if (response.status === 200) {
                await get_copying_data();
            }
        } catch (error_response) {
            console.error('Error cancellation of the copying', error_response);
        }
    }

    useEffect(() => {
        get_copying_data();
    }, [url]);

    useEffect(() => {
        setLocation("copying")
    }, [setLocation])
    return (
        <>
            <section className="experts-product">
                <div className="experts-product-column">
                    <Notification/>
                    <div className="my-trading-system copying">
                        <div className="my-trading-system-header">
                            <h2 className="my-trading-system-title">Копирование</h2>
                            <h3 className="my-trading-system-subtitle">Описание страницы</h3>
                            <span className="error"></span>
                        </div>
                        {loading ? (
                            <>
                                {!copyingData ? (
                                    <div className="replacing-account-number-column">
                                        <div className="method">
                                            <span className="method-text">Брокер</span>
                                            <div className="method-input-block-base">
                                                <div className="method-input-block" onClick={open_options}>
                                <span
                                    className="method-input-placeholder">{broker.length > 0 ? broker : "Выберите брокера"}</span>
                                                    <i className="fa fa-chevron-down"></i>
                                                </div>
                                                <div className="method-options-block">
                                                    <ul className="method-options-list">
                                                        <li className="method-options-item"
                                                            onClick={() => {
                                                                setBroker("Roboforex");
                                                                open_options();
                                                            }}>Roboforex
                                                        </li>
                                                        {/*<li className="method-options-item" onClick={() => {*/}
                                                        {/*    setBroker("Forex4You");*/}
                                                        {/*    open_options();*/}
                                                        {/*}}>Forex4You*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                            <span className="method-text-error input1"></span>
                                        </div>
                                        <div className="wallet-address">
                                            <span className="method-text">Новый номер счёта</span>
                                            <input onChange={(event) => setAccountNumber(event.target.value)}
                                                   type="text"
                                                   placeholder="Введите номер торгового счёта"
                                                   className="method-input-block-base"/>
                                            <span className="method-text-error input2"></span>
                                        </div>
                                        <div className="checkbox-block">
                                            <div className="checkbox-block-row">
                                                <input type="checkbox" className="checkbox" checked={checkbox}
                                                       onChange={(event) => setCheckbox(event.target.checked)}/>
                                                <span className="checkbox-text">Я принимаю <Link to="#"
                                                                                                 className="invite-friend-link">условия услуги копирования</Link></span>
                                            </div>
                                            <span className="method-text-error checkbox-error"></span>
                                        </div>
                                        <button className="method-of-replenishment-btn"
                                                onClick={() => create_copying()}>Подать заявку
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <div className="wallet-withdrawal-status-column">
                                            <ul className="wallet-withdrawal-status-info">
                                                <li className="wallet-withdrawal-status-row">
                                                    <span className="wallet-withdrawal-status-title">{copyingData.status === "pending" ? "В обработке" : copyingData.status === "completed" ? "Завершена" : copyingData.status}</span>
                                                    <span>Новая</span>
                                                </li>
                                                <li className="wallet-withdrawal-status-row">
                                                    <span
                                                        className="wallet-withdrawal-status-title">Номер счёта</span>
                                                    <span>{copyingData.account_number}</span>
                                                </li>
                                            </ul>
                                            <button onClick={() => cancel_copying(copyingData.id)} className="method-of-replenishment-btn">Отменить операцию</button>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <span className="loading-text">Loading...</span>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Copying;