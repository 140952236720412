import React, {useEffect, useState} from "react";
import "./withdrawal_wallet.css"
import {Link} from "react-router-dom";
import axios from "axios";

const WithdrawalWallet = ({url}) => {
    const [withdrawData, setWithdrawData] = useState(null);
    const [method, setMethod] = useState("");
    const [cryptocurrency, setCryptocurrency] = useState("");
    const [cryptoWallet, setCryptoWallet] = useState("");
    const [transferAmount, setTransferAmount] = useState(0.0);
    const [checkbox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);

    const get_withdraw_data = async () => {
            try {
                const response = await axios.get(`${url}/wallet/get-pending-withdraw`, {withCredentials: true})
                setWithdrawData(response.data);
                setLoading(true);
            } catch (error_response) {
                console.error('Error fetching withdraw data', error_response);
            }
        }

    useEffect(() => {
        get_withdraw_data();
    }, [url]);

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const open_cryptocurrencies = () => {
        const block = document.querySelector(".cryptocurrencies-options-block")
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const top_up_with_cryptocurrency = async () => {
        const error_block = document.querySelector(".method-text-error.input");
        const error_block_2 = document.querySelector(".method-text-error.input2");
        const error_block_3 = document.querySelector(".method-text-error.input3");
        const error_block_4 = document.querySelector(".method-text-error.input4");
        const error_checkbox = document.querySelector(".method-text-error.checkbox-error");

        let hasErrors = false;

        // Проверка поля method
        if (method.length === 0 && error_block) {
            error_block.innerText = "Поле не заполнено";
            hasErrors = true;
        } else {
            error_block.innerText = "";
        }

        // Проверка поля cryptocurrency
        if (method.length > 0 && cryptocurrency.length === 0 && error_block_2) {
            error_block_2.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_2) {
            error_block_2.innerText = "";
        }

        // Проверка поля cryptoWallet
        if (cryptoWallet.length < 10 && error_block_3) {
            error_block_3.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_3) {
            error_block_3.innerText = "";
        }

        // Проверка поля transferAmount
        if (transferAmount < 15 && error_block_4) {
            error_block_4.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_4) {
            error_block_4.innerText = "";
        }

        // Проверка чекбокса
        if (!checkbox) {
            error_checkbox.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_checkbox.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/wallet/create-withdraw`,
                    {
                        "wallet_number": cryptoWallet,
                        "amount": transferAmount
                    },
                    {withCredentials: true})
                if (response.status === 200) {
                    await get_withdraw_data();
                }
            } catch (error_response) {
                console.error('Error creating withdraw', error_response);
                error_checkbox.innerText = error_response.response.data.detail ? error_response.response.data.detail : "Неизвестная ошибка!";
            }
        }
    };

    const cancel_withdraw = async (withdraw_id) => {
        try {
            const response = await axios.post(`${url}/wallet/cancel-withdraw?withdraw_id=${withdraw_id}`,
                {},
                {withCredentials: true})
            if (response.status === 200) {
                await get_withdraw_data();
            }
        } catch (error_response) {
            console.error('Error cancellation of the refund', error_response);
        }
    }

    return (
        <>
            <div className="method-of-replenishment withdrawal">
                {loading ? (
                    <>
                        {!withdrawData ? (
                            <>
                                <div className="method-of-replenishment-first-column">
                                    <div className="method">
                                        <span className="method-text">Способ вывода</span>
                                        <div className="method-input-block-base">
                                            <div className="method-input-block" onClick={open_options}>
                                <span
                                    className="method-input-placeholder">{method === "cryptocurrency" ? "Криптовалюты" : "Выберите способ вывода"}</span>
                                                <i className="fa fa-chevron-down"></i>
                                            </div>
                                            <div className="method-options-block">
                                                <ul className="method-options-list">
                                                    <li className="method-options-item" onClick={() => {
                                                        setMethod("cryptocurrency");
                                                        open_options();
                                                    }}>Криптовалюты
                                                    </li>
                                                    <li className="method-options-item none">Банковская карта (скоро)
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <span className="method-text-error input"></span>
                                    </div>
                                    {method === "cryptocurrency" && (
                                        <>
                                            <div className="method">
                                                <span className="method-text">Криптовалюты</span>
                                                <div className="method-input-block-base">
                                                    <div className="method-input-block" onClick={open_cryptocurrencies}>
                                    <span
                                        className="method-input-placeholder">{cryptocurrency.length > 0 ? cryptocurrency : "Выберите"}</span>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                    <div className="cryptocurrencies-options-block">
                                                        <ul className="method-options-list">
                                                            <li className="method-options-item" onClick={() => {
                                                                setCryptocurrency("USDT TRC-20")
                                                                open_cryptocurrencies()
                                                            }}>USDT TRC-20
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <span className="method-text-error input2"></span>
                                            </div>
                                            <div className="wallet-address">
                                                <span className="method-text">Адрес кошелька</span>
                                                <input onChange={(event) => setCryptoWallet(event.target.value)}
                                                       type="text"
                                                       placeholder="Адрес кошелька USDT TRC-20"
                                                       className="method-input-block-base"/>
                                                <span className="method-text-error input3"></span>
                                            </div>
                                            <div className="withdrawal-amount">
                                                <span className="method-text">Сумма вывода</span>
                                                <input onChange={(event) => setTransferAmount(event.target.value)}
                                                       type="number"
                                                       placeholder="0.00"
                                                       className="method-input-block-base"/>
                                                <span className="method-text-error input4"></span>
                                            </div>
                                        </>
                                    )}
                                    <div className="checkbox-block">
                                        <div className="checkbox-block-row">
                                            <input type="checkbox" className="checkbox" checked={checkbox}
                                                   onChange={(event) => setCheckbox(event.target.checked)}/>
                                            <span className="checkbox-text">Я принимаю <Link to="#"
                                                                                             className="invite-friend-link">условия вывода</Link> на криптовалютный кошелёк.</span>
                                        </div>
                                        <span className="method-text-error checkbox-error"></span>
                                    </div>
                                    <button className="method-of-replenishment-btn"
                                            onClick={top_up_with_cryptocurrency}>Отправить
                                        заявку на вывод
                                    </button>
                                </div>
                                <div className="method-of-replenishment-second-column withdrawal">
                    <span className="method-of-replenishment-text">Минимальная сумма вывода: <span
                        className="method-of-replenishment-text orange">15 $</span></span>
                                    <p className="method-of-replenishment-text">Комиссия на вывод составляет: <span
                                        className="method-of-replenishment-text orange">1.5 USDT + 2%</span>.</p>
                                    <p className="method-of-replenishment-text">Заявки на вывод исполняются каждую
                                        пятницу <span
                                            className="method-of-replenishment-text orange">до <br/>23:59 (по GMT +3)</span>
                                    </p>
                                </div>
                            </>
                        ) : (
                            <div className="wallet-withdrawal-status-column">
                                <div className="wallet-withdrawal-alert">Ваша заявка на вывод средств в очереди.</div>
                                <ul className="wallet-withdrawal-status-info">
                                    <li className="wallet-withdrawal-status-row">
                                        <span className="wallet-withdrawal-status-title">Статус</span>
                                        <span>{withdrawData.status === "pending" ? "В обработке" : withdrawData.status === "completed" ? "Завершена" : withdrawData.status}</span>
                                    </li>
                                    <li className="wallet-withdrawal-status-row">
                                        <span className="wallet-withdrawal-status-title">Способ оплаты</span>
                                        <span>{withdrawData.gateway === "cryptocurrency" ? "Криптовалюты" : withdrawData.gateway}</span>
                                    </li>
                                    <li className="wallet-withdrawal-status-row">
                                        <span className="wallet-withdrawal-status-title">Валюта</span>
                                        <span>{withdrawData.details.currency}</span>
                                    </li>
                                    <li className="wallet-withdrawal-status-row">
                                        <span className="wallet-withdrawal-status-title">Сумма</span>
                                        <span>{withdrawData.details.amount} USDT</span>
                                    </li>
                                    <li className="wallet-withdrawal-status-row">
                                        <span className="wallet-withdrawal-status-title">Кошелёк</span>
                                        <span>{withdrawData.details.address}</span>
                                    </li>
                                </ul>
                                <button onClick={async () => await cancel_withdraw(withdrawData.id)}
                                        className="method-of-replenishment-btn">Отменить операцию
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <span className="loading-text">Loading...</span>
                )}
            </div>
        </>
    )
}

export default WithdrawalWallet