import React, {useEffect, useState} from "react";
import "./TradeCapitalAccount.css";
import Notification from "../../../components/Notification/Notification";
import {Link, useParams} from "react-router-dom";
import TradeCapitalBaseScreen from "./TradeCapitalBaseScreen/TradeCapitalBaseScreen";
import TradeCapitalReplacingAccountNumberScreen
    from "./TradeCapitalReplacingAccountNumberScreen/TradeCapitalReplacingAccountNumberScreen";
import axios from "axios";

const TradeCapitalAccount = ({setLocation, url}) => {
    const [selectedLink, setSelectedLink] = useState("base");
    const {id} = useParams();
    const [tCAccount, setTCAccount] = useState({});

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".my-trading-system-nav-btn");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
        });

        event.target.classList.add("active");
    }

    useEffect(() => {
        setLocation("trade-capital/account");
    }, [setLocation]);

    useEffect(() => {
        const get_tc_account = async () => {
            try {
                if (id) {
                    const response = await axios.get(`${url}/account/get-tc-account?account_number=${id}`, {withCredentials: true});
                    setTCAccount(response.data);
                }
            } catch (error_response) {
                console.error('Error fetching TC data', error_response);
            }
        }
        get_tc_account()
    }, [id, url]);

    return (
        <>
            <section className="experts-product">
                <div className="experts-product-column">
                    <Notification/>
                    <Link to="/trade-capital" className="experts-product-back-btn">
                        <i className="fa fa-chevron-left"></i>
                        назад
                    </Link>
                    <div className="my-trading-system">
                        <div className="my-trading-system-header">
                            <h2 className="my-trading-system-title">Торговая система TC</h2>
                            <h3 className="my-trading-system-subtitle">Детализация и управление торговой системой
                                TC</h3>
                        </div>
                        <div className="my-trading-system-screens">
                            <ul className="my-trading-system-nav-list">
                                <li className="my-trading-system-nav-item">
                                    <button className="my-trading-system-nav-btn active"
                                            onClick={(event) => {
                                                active_button(event);
                                                setSelectedLink("base");
                                            }}>Общее
                                    </button>
                                </li>
                                <li className="my-trading-system-nav-item">
                                    <button className="my-trading-system-nav-btn "
                                            onClick={(event) => {
                                                active_button(event);
                                                setSelectedLink("replacing the account number");
                                            }}>Замена номера счёта
                                    </button>
                                </li>
                            </ul>
                            {selectedLink === "base" ? (
                                <TradeCapitalBaseScreen tCAccount={tCAccount} url={url}/>
                            ) : selectedLink === "replacing the account number" && (
                                <TradeCapitalReplacingAccountNumberScreen tCAccount={tCAccount} url={url}/>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TradeCapitalAccount;