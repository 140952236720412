import React, {useState} from 'react';
import {Link} from "react-router-dom";
import "./header.css";
import axios from "axios";
import HideDrawer from "../HideDrawer/HideDrawer";

const Header = ({url, userData, location}) => {
    const {
        email,
        name,
        balance,
    } = userData || {};
    const [showDrawer, setShowDrawer] = useState(false);

    const open_account_info = () => {
        const section = document.querySelector(".dropdown-menu");
        const transparent_block = document.querySelector(".transparent-block");
        if (section.classList.contains("open")) {
            section.classList.remove("open");
            transparent_block.classList.remove("open");
        } else {
            section.classList.add("open");
            transparent_block.classList.add("open");
        }
    }

    const logout = async () => {
        await axios.post(`${url}/user/logout`, {}, {withCredentials: true})
            .then(response => {
                if (response.status === 200) {
                    window.location.href = "/login"
                }
            })
    }

    const open_drawer = () => {
        const drawer = document.querySelector(".hide-drawer-black-block");
        if (drawer.classList.contains("open")) {
            drawer.classList.remove("open");
            setShowDrawer(false);
        } else {
            drawer.classList.add("open");
            setShowDrawer(true);
        }
    }

    return (
        <>
            <header>
                <div className="container">
                    <div className="header-row">
                        <div className="logo">
                            <Link to="#" className="logo-link">
                                <img src="/crown.png" alt="Logo Crown" className="logo-img"/>
                                <img src="/royal-family.png" alt="Logo Royal Family" className="logo-img"/>
                            </Link>
                        </div>
                        <nav className="nav">
                            <ul className="nav-list">
                                <li className="nav-item">
                                    <Link to="/partners/invite" className="nav-link">Пригласить друга</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="https://t.me/RoyalFamily_Support_bot" className="nav-link">
                                        <i className="fa fa-headset"/>
                                        <span>Поддержка</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link">
                                        <img src="/wallet-icon.svg" alt="Wallet" className="wallet-icon"/>
                                        <span>{balance / 100} $</span>
                                    </Link>
                                </li>
                                <div className="transparent-block" onClick={open_account_info}></div>
                                <li className="nav-item" onClick={open_account_info}>
                                    <button className="nav-link btn">{name[0]}</button>
                                    <i className="fa fa-chevron-down dropdown-arrow"></i>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-menu-account">
                                            <span className="dropdown-menu-name">{name}</span>
                                            <span className="dropdown-menu-email">{email}</span>
                                        </div>
                                        <div className="dropdown-menu-logout">
                                            <button onClick={logout} className="dropdown-menu-btn">Выйти</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <button className="menu-drawer-btn" onClick={open_drawer}>
                            <img src="/menu.svg" alt="Меню" className="menu-drawer-btn-img"/>
                        </button>
                    </div>
                </div>
            </header>
            <section className="hide-drawer-black-block" onClick={open_drawer}></section>
            <HideDrawer open_drawer={open_drawer} showDrawer={showDrawer} userData={userData} location={location}/>
        </>
    )
}

export default Header;