import React, {useEffect} from "react";
import "./MetricaFX.css";
import Notification from "../../components/Notification/Notification";
import Notification2 from "../../components/Notification/Notification2";

const MetricaFX = ({setLocation}) => {
    useEffect(() => {
        setLocation("metricafx");
    }, [setLocation]);

    return (
        <>
            <section className="metricafx-section">
                <div className="metricafx-column">
                    <Notification/>
                    <Notification2/>
                    <div className="hero metricafx">
                        <div className="hero-row metricafx">
                            <div className="metricafx-explanation">
                                <h2 className="metricafx-title">MetricaFX</h2>
                                <span className="metricafx-title-description">
                                    Мониторинг торговых счетов на платформе <br/>MetaTrader 5, Metatrader 4
                                </span>
                            </div>
                            <div className="metricafx-video">
                                <iframe width="320" height="185" style={{borderRadius: "20px"}}
                                        src="https://www.youtube.com/embed/4FzuDDYOHbA?si=D_ZXh2N6VHIxAEzi"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen="">
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <div className="metricafx-advantages">
                        <div className="metricafx-advantages-card">
                            <div className="advantages-card-img-container">
                                <img src="/function.svg" alt="Function" className="advantages-card-img"/>
                            </div>
                            <h3 className="advantages-card-title">Статистика торговли</h3>
                            <span className="advantages-card-description">Оперативная информация по текущему состоянию торговых счетов с развернутой статистикой.</span>
                        </div>
                        <div className="metricafx-advantages-card">
                            <div className="advantages-card-img-container">
                                <img src="/shield.svg" alt="Function" className="advantages-card-img"/>
                            </div>
                            <h3 className="advantages-card-title">Быстрый доступ</h3>
                            <span className="advantages-card-description">Доступ с любых устройств к вашим счетам в режиме онлайн. Данные обновляются каждые 5 сек.</span>
                        </div>
                        <div className="metricafx-advantages-card">
                            <div className="advantages-card-img-container">
                                <img src="/medal.svg" alt="Function" className="advantages-card-img"/>
                            </div>
                            <h3 className="advantages-card-title">Оперативные уведомления</h3>
                            <span className="advantages-card-description">Получайте оповещения в Telegram о самом важном по вашему счету в режиме реального времени.</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MetricaFX;