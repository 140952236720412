import React, {useEffect} from "react";
import "./ErrorPage.css";

const ErrorPage = ({setLocation}) => {
    useEffect(() => {
        setLocation("error-page");
    }, [setLocation]);

    return (
        <>
            <section onClick={() => window.location.href = "/"} className="error-page">
                <div className="container error-container">
                    <span className="error-page-title">404 | NOT FOUND</span>
                </div>
            </section>
        </>
    )
}

export default ErrorPage;