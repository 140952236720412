import React, {useEffect} from "react";
import "./AdminSubscriptions.css";
import {Link} from "react-router-dom";

const AdminSubscriptions = ({setLocationAdmin, url}) => {

    useEffect(() => {
        setLocationAdmin("admin-tariffs")
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Подписки</h2>
                <div className="admin-filter rf-grey-block">
                    <div className="admin-filter-row">
                        <h3 className="admin-base-row-title">Фильтр</h3>
                        <div className="admin-filter-btns">
                            <button className="admin-filter-reset">Сбросить</button>
                            <button className="admin-filter-apply">Применить</button>
                        </div>
                    </div>
                    <input type="text" placeholder="Что ищем?" className="admin-filter-input"/>
                </div>
                <div className="admin-filter rf-grey-block">
                    <h3 className="admin-base-row-title">Список подписок</h3>
                    <table className="admin-users-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Пользователь</th>
                                <th>Дней до истечения</th>
                                <th>Истекает</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>11255</th>
                                <th>
                                    <div className="admin-users-table-user-row">
                                        <img src="/admin%20icons/avatar2.jpg" alt="Avatar"
                                             className="admin-users-table-user-avatar"/>
                                        <div className="admin-users-table-user-info">
                                            <span className="admin-users-table-user-username">Nochsova</span>
                                            <span className="admin-users-table-user-email">sovanoch22@gmail.com</span>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <span className="admin-users-table-user-email">364</span>
                                </th>
                                <th>
                                    <div className="admin-users-table-user-info">
                                        <span className="admin-users-table-user-email">2025-11-02</span>
                                        <span className="admin-users-table-user-email">12:57:38</span>
                                    </div>
                                </th>
                                <th className="th-btns">
                                    <div className="admin-users-table-btns">
                                        <Link to="#" className="admin-users-table-edit">
                                            <img src="/admin%20icons/pencil.svg" alt="Edit"
                                                 className="admin-users-table-edit-img"/>
                                        </Link>
                                        <Link to="#" className="admin-users-table-edit">
                                            <img src="/admin%20icons/eye.svg" alt="Login"
                                                 className="admin-users-table-edit-img"/>
                                        </Link>
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <div className="pagination">
                            <span
                                className="small-text">Пагинация
                            </span>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdminSubscriptions;