import React, {useEffect, useState} from "react";
import "./TradeCapital.css"
import Notification from "../../components/Notification/Notification";
import {Link, useOutletContext} from "react-router-dom";
import {date_formatter} from "../../components/formatter";

const TradeCapital = ({setLocation}) => {
    const {tCAccounts} = useOutletContext() || {};

    useEffect(() => {
        setLocation("trade-capital");
    }, [setLocation]);

    return (
        <>
            <section className="trade-capital">
                <div className="trade-capital-column">
                    <Notification/>
                    <div className="hero trade-capital">
                        <div className="hero-row trade-capital">
                            <div className="trade-capital-banner-text">
                                <h2 className="trade-capital-banner-title">Торговые системы Trade Capital</h2>
                                <span className="trade-capital-banner-subtitle">
                                    Перенесённые торговые системы
                                    <br/>
                                    по программе поддержки пользователей Trade Capital
                                </span>
                            </div>
                            <img src="/logo-trade-capital.png" alt="Logo Trade Capital" className="trade-capital-img"/>
                        </div>
                    </div>
                    <table className="trade-capital-table">
                        <thead>
                        <tr className="trade-capital-table-head">
                            <th>Номер счета</th>
                            <th>Истекает</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {tCAccounts && (
                            tCAccounts.map((tc_account) => (
                                <tr key={tc_account.id} className="trade-capital-table-row">
                                    <td>{tc_account.account_number}</td>
                                    <td>
                                        <div className="experts-table-th-column">
                                            <span>{date_formatter(tc_account.expires_at)}</span>
                                            {new Date(tc_account.expires_at) < new Date() && (
                                                <span
                                                    className="red-text">истекла</span>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <Link to={`/trade-capital/account/${tc_account.account_number}`}
                                              className="yellow-text experts-yellow-text">просмотр</Link>
                                    </td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                    <div className="operation-history-column">
                        <ul className="operation-history-ul">
                            {/*{operations.map((operation) => (*/}
                            <li className="operation-history-item">
                                <div className="operation-history-item-row">
                                    <span>503649433</span>
                                    <Link to="/trade-capital/account" className="yellow-text">просмотр</Link>
                                </div>
                                <div className="operation-history-item-row">
                                    <span className="operation-history-time">20.09.2024 в 23:59</span>
                                    <span className="red">истекла</span>
                                </div>
                            </li>
                            <li className="operation-history-item">
                                <div className="operation-history-item-row">
                                    <span>503649433</span>
                                    <Link to="/trade-capital/account" className="yellow-text">просмотр</Link>
                                </div>
                                <div className="operation-history-item-row">
                                    <span className="operation-history-time">20.09.2024 в 23:59</span>
                                    <span className="red">истекла</span>
                                </div>
                            </li>
                            <li className="operation-history-item">
                                <div className="operation-history-item-row">
                                    <span>503649433</span>
                                    <Link to="/trade-capital/account" className="yellow-text">просмотр</Link>
                                </div>
                                <div className="operation-history-item-row">
                                    <span className="operation-history-time">20.09.2024 в 23:59</span>
                                    <span className="red">истекла</span>
                                </div>
                            </li>
                            {/*))}*/}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TradeCapital;