import "./MyAccount.css";
import Notification from "../../components/Notification/Notification";
import React, {useEffect, useState} from "react";
import {Link, useOutletContext} from "react-router-dom";
import axios from "axios";
import {date_formatter} from "../../components/formatter";

const MyAccount = ({setLocation, url}) => {
    const {userData} = useOutletContext() || {};
    const [accountData, setAccountData] = useState();
    const [accountNumber, setAccountNumber] = useState("")
    const [metricaData, setMetricaData] = useState("");
    const [isLoading, setIsLoading] = useState(true)

    const {
        name,
        wallet_number
    } = userData || {};

    const {
        ea_name,
        ea_version,
        profit_total_per,
        profit_today_per,
        profit_yesterday_per,
        profit_week_per,
        profit_month_per,
        terminal_time
    } = metricaData || {};

    useEffect(() => {
        setLocation("my-account");
    }, [setLocation]);

    const account_active = (event) => {
        const listBtns = document.querySelectorAll(".accounts-item-btn");

        listBtns.forEach((li) => {
            li.classList.remove("active");
        });

        event.target.classList.add("active");
    }

    function capitalizeFirstLetter(str) {
        if (!str) return ''; // Обработка пустой строки
        let string = str.replace("_PLUS", "+").split("_")
        let result = "";
        for (let i = 0; i < string.length; i++) {
            result += string[i].charAt(0).toUpperCase() + string[i].slice(1).toLowerCase() + " ";
        }
        return result
    }

    useEffect(() => {
        const accountsElement = document.querySelector('.accounts');
        let isScrolling;

        if (accountsElement){
            const handleScroll = () => {
            // Показываем скроллбар при прокрутке
            accountsElement.classList.remove('hidden-scrollbar');

            // Очищаем таймер скрытия
            clearTimeout(isScrolling);

            // Скрываем скроллбар через 1 секунду бездействия
            isScrolling = setTimeout(() => {
                accountsElement.classList.add('hidden-scrollbar');
            }, 1000);
        };

        accountsElement.addEventListener('scroll', handleScroll);

        return () => {
            accountsElement.removeEventListener('scroll', handleScroll);
        };
        }
    }, []);

    useEffect(() => {
        const getAccountData = async () => {
            try {
                const response = await axios.get(`${url}/account/get-all-accounts`, {withCredentials: true});
                setAccountData(response.data);
                if (response.data.length > 0) {
                    setAccountNumber(response.data[0].account_number)
                    setIsLoading(false);
                }
            } catch (error_response) {
                console.error('Error fetching accounts data', error_response);
            }
        }
        getAccountData();
    }, [url]);

    useEffect(() => {
        const getMetricaData = async () => {
            try {
                const response = await axios.get(`${url}/report/get-report?account_number=${accountNumber}`, {withCredentials: true});
                if (response.data) {
                    setMetricaData(response.data);
                } else {
                    setMetricaData(null);
                }
            } catch (error_response) {
                console.error('Error fetching metrica data', error_response);
            }
        }
        getMetricaData()
    }, [accountNumber, url]);

    return (
        <>
            <section className="my-account">
                <div className="my-account-column">
                    <Notification/>
                    <div className="hero my-account">
                        <div className="hero-row">
                            <div className="first-column">
                                <div className="statistic">
                                    <h3 className="statistic-title">Статистика счетов</h3>
                                    {!isLoading && (
                                        <>
                                            {accountData ? (
                                        <div className="statistic-info">
                                            <div className="account-statistic">
                                                {metricaData ? (
                                                    <>
                                                        <h3 className="account-statistic-title">{capitalizeFirstLetter(ea_name)}<span>{ea_version}</span>
                                                        </h3>
                                                        <div className="account-statistic-total">
                                                                <span
                                                                    className="account-statistic-total-title">Всего</span>
                                                            <span
                                                                className={`account-statistic-total-percent ${+profit_total_per < 0 && "red"}`}>{profit_total_per}%</span>
                                                        </div>
                                                        <div className="account-statistic-total">
                                                            <div className="account-statistic-period">
                                                            <span
                                                                className="account-statistic-total-title">За вчера</span>
                                                                <span
                                                                    className={`account-statistic-period-percent ${+profit_today_per < 0 && "red"}`}>{profit_today_per}%</span>
                                                            </div>
                                                            <div className="account-statistic-period">
                                                            <span
                                                                className="account-statistic-total-title">За позавчера</span>
                                                                <span
                                                                    className={`account-statistic-period-percent ${+profit_yesterday_per < 0 && "red"}`}>{profit_yesterday_per}%</span>
                                                            </div>
                                                            <div className="account-statistic-period">
                                                        <span
                                                            className="account-statistic-total-title">За неделю</span>
                                                                <span
                                                                    className={`account-statistic-period-percent ${+profit_week_per < 0 && "red"}`}>{profit_week_per}%</span>
                                                            </div>
                                                            <div className="account-statistic-period">
                                                        <span
                                                            className="account-statistic-total-title">За месяц</span>
                                                                <span
                                                                    className={`account-statistic-period-percent ${+profit_month_per < 0 && "red"}`}>{profit_month_per}%</span>
                                                            </div>
                                                        </div>
                                                        <div className="account-statistic-date">
                                                        <span
                                                            className="account-statistic-date-title">Данные от</span>
                                                            <span
                                                                className="account-statistic-date-title">{date_formatter(terminal_time)}</span>
                                                        </div>
                                                    </>
                                                ) : <span className="no-data">Нет данных</span>}
                                            </div>
                                            <div className="accounts">
                                                <ul className="accounts-list">
                                                    {accountData ? (
                                                        <>
                                                            {accountData.map((account) => (
                                                                <>
                                                                    {account.account_number && (
                                                                        <li key={account.id} className="accounts-item">
                                                                            <button
                                                                                className={`accounts-item-btn ${accountNumber === account.account_number ? "active" : null}`}
                                                                                onClick={(event) => {
                                                                                    account_active(event);
                                                                                    setAccountNumber(account.account_number);
                                                                                }}>
                                                                        <span
                                                                            className="accounts-item-nums">{account.account_number}</span>
                                                                                <img src="/roboforex.png"
                                                                                     alt="Roboforex"
                                                                                     className="accounts-item-img"/>
                                                                            </button>
                                                                        </li>
                                                                    )}
                                                                </>
                                                            ))
                                                            }
                                                        </>
                                                    ) : null}
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="my-trading-system-header" style={{display: "flex", alignItems: "center"}}>
                                            <h3 className="my-trading-system-subtitle" style={{fontSize: "16px", marginBottom: "8px"}}>У вас нет активных торговых счетов.</h3>
                                            <Link to="/experts/buy" className="method-of-replenishment-btn" style={{display: "flex", fontSize: "12px", padding: "0", width: "175px", height: "40px", justifyContent: "center", alignItems: "center"}}>Купить торговую систему</Link>
                                        </div>
                                    )}
                                        </>
                                    )}
                                </div>
                                <div className="manuals">
                                <h3 className="statistic-title">Инструкции</h3>
                                    <ul className="manuals-list">
                                        <li className="manuals-item">
                                            <Link to="https://teletype.in/@royalfamily.club/registraciya_robo"
                                                  className="manuals-link">Регистрация на
                                                Roboforex</Link>
                                        </li>
                                        <li className="manuals-item">
                                            <Link to="https://teletype.in/@royalfamily.club/sozdanie_scheta_robo"
                                                  className="manuals-link">Создание торгового
                                                счета</Link>
                                        </li>
                                        <li className="manuals-item">
                                            <Link to="https://teletype.in/@royalfamily.club/popolnenie_robo"
                                                  className="manuals-link">Пополнение торгового
                                                счета</Link>
                                        </li>
                                        <li className="manuals-item">
                                            <Link to="https://teletype.in/@royalfamily.club/pokupka_vps"
                                                  className="manuals-link">Покупка VPS</Link>
                                        </li>
                                        <li className="manuals-item">
                                            <Link to="https://teletype.in/@royalfamily.club/ustanovka_robota"
                                                  className="manuals-link">Установка робота</Link>
                                        </li>
                                        <li className="manuals-item">
                                            <Link to="https://teletype.in/@royalfamily.club/dop_instrukcii"
                                                  className="manuals-link">Другие инструкции</Link>
                                        </li>
                                        <li className="manuals-item">
                                            <Link
                                                to="https://www.youtube.com/watch?v=M2bFBeedUeo&list=PLJZ8HGR9_q8rRO-yyhf3hZo2ngne_FO2t"
                                                className="manuals-link"><span
                                                className="badge">Внимание</span> Все видео инструкции</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="second-column">
                                <div className="user-card">
                                    <div className="user-card-icons">
                                        <img src="/card-chip.png" alt="Card Chip" className="user-card-icon"/>
                                        <img src="/wireless.png" alt="Wireless" className="user-card-icon"/>
                                    </div>
                                    <div className="user-card-info">
                                        <div className="user-card-name">
                                            <span className="user-card-name grey">Владелец</span>
                                            <span className="user-card-name">{name}</span>
                                        </div>
                                        <div className="user-card-account">
                                            <span className="user-card-name grey">Номер счёта</span>
                                            <span className="user-card-name">{wallet_number}</span>
                                        </div>
                                    </div>
                                    <div className="user-card-info">
                                        <div className="user-card-account">
                                            <span className="user-card-name grey">Баланс</span>
                                            <span
                                                className="user-card-name balance bold">{userData && userData.balance / 100} $</span>
                                        </div>
                                        {userData && userData.subscribe && (
                                            <div className="user-card-account">
                                                <span className="user-card-name grey">Тариф</span>
                                                <span className="user-card-name">{userData.subscribe.name}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="top-partners">
                                    <h3 className="statistic-title">Топ 5 партнёров</h3>
                                    <div className="top-partners-info">Скоро</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MyAccount;