import React from "react";
import "./BaseScreen.css"
import {date_formatter} from "../../../../components/formatter";
import axios from "axios";

const BaseScreen = ({url, account}) => {
    const open_question = (index) => {
        // Получаем все элементы с классом .base-questions-item-description
        const descriptionList = document.querySelectorAll(".base-questions-item-description");

        // Проходим по каждому элементу
        descriptionList.forEach((item, i) => {
            if (i === index) {
                // Если элемент с нужным индексом, то переключаем класс open
                if (item.classList.contains("open")) {
                    item.classList.remove("open");
                } else {
                    item.classList.add("open")
                }
            }
        });
    }

    const handleDownload = async (link_to_download, filename) => {

        try {
            // Запрос файла через axios
            const response = await axios.get(link_to_download, {
                responseType: "blob",
            });

            // Создание временного URL из Blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename); // Указание имени файла
            document.body.appendChild(link);
            link.click(); // Инициируем скачивание
            document.body.removeChild(link);

            // Освобождаем память
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Ошибка скачивания файла:", error);
        }
    };

    return (
        <>
            <div className="base-row">
                <div className="base-info">
                    {account && (
                        <>
                            <div className="base-hero">
                                <div className="base-hero-row">
                                    <span className="base-hero-title">Номер счёта</span>
                                    <span className="base-hero-info">{account.account_number}</span>
                                </div>
                                <div className="base-hero-row">
                                    <span className="base-hero-title">Брокер</span>
                                    <span className="base-hero-info">{account.broker}</span>
                                </div>
                                <div className="base-hero-row">
                                    <span className="base-hero-title">Подписка до</span>
                                    <span className="base-hero-info">{date_formatter(account.expires_at)}</span>
                                </div>
                                <div className="base-hero-column">
                                    <span className="base-hero-title">Ключ</span>
                                    <span className="base-hero-info">{account.external_id}</span>
                                </div>
                            </div>
                            <div className="hero base-hero">
                                {account.product && account.product.is_downloadable && (
                                    <>
                                        <div className="base-hero-row">
                                            <span className="base-hero-title">Продукт</span>
                                            <span className="base-hero-info">{account.product.name}</span>
                                        </div>
                                        <div className="base-hero-row">
                                            <span className="base-hero-title">Файл MT5</span>
                                            <button
                                                onClick={() =>
                                                    handleDownload(`${url}/static/${account.product.expert_mt5.files[0]}`, `${account.product.name}_${account.product.version}.${account.product.expert_mt5.filename.split(".")[1]}`)
                                                }
                                                className="base-hero-btn"
                                            >
                                                Скачать {account.product && account.product.version}
                                            </button>
                                        </div>
                                        <div className="base-hero-row">
                                            <span className="base-hero-title">Файл MT4</span>
                                            <button
                                                onClick={() =>
                                                    handleDownload(`${url}/static/${account.product.expert_mt4.files[0]}`, `${account.product.name}_${account.product.mt4_version}.${account.product.expert_mt4.filename.split(".")[1]}`)
                                                }
                                                className="base-hero-btn"
                                            >
                                                Скачать {account.product && account.product.mt4_version}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="base-questions">
                    <h2 className="base-questions-title">Частые вопросы</h2>
                    <span className="base-questions-item" onClick={() => open_question(0)}>У меня установлена старая версия продукта (напр.: Nova 2.04 и
                        ниже), как мне обновиться?</span>
                    <span className="base-questions-item-description">
                        Чтобы обновить версию продукта, необходимо перейти в раздел "Замена торговой системы", выбрать
                        продукт и нажать на кнопку «Заменить». После этого наша система автоматически актуализирует ваши
                        данные.
                    </span>
                    <span className="base-questions-item"
                          onClick={() => open_question(1)}>Что делать если у меня истекла подписка?</span>
                    <span className="base-questions-item-description">
                        Ничего страшного. Если у Вас истекла подписка, то Вы можете просто продлить её и сервисы вновь будут активны.
                    </span>
                </div>
            </div>
        </>
    )
}

export default BaseScreen