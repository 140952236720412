import React, {useEffect, useState} from "react";
import "./AdminProductEdit.css";
import {Link, useParams} from "react-router-dom";
import MarkdownEditor from "../../../../components/MarkdownEditor/MarkdownEditor";
import axios from "axios";

const AdminProductEdit = ({setLocationAdmin, url}) => {
    const [product, setProduct] = useState(null);
    const {id} = useParams();
    const error_text = document.querySelector(".error");
    const [status, setStatus] = useState(null)
    const [isDownloadable, setIsDownloadable] = useState(null)
    const [title, setTitle] = useState(null)
    const [serviceKey, setServiceKey] = useState(null)
    const [description, setDescription] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [version5, setVersion5] = useState(null)
    const [fileMt5, setfileMt5] = useState(null);
    const [version4, setVersion4] = useState(null)
    const [fileMt4, setfileMt4] = useState(null);

    const update_product = async () => {
        try {
            const formData = new FormData();
            formData.append("product_id", id);
            formData.append("enabled", status);
            formData.append("is_downloadable", isDownloadable);
            formData.append("name", title);
            formData.append("description", description)
            formData.append("service_key", serviceKey);
            formData.append("version", version5);
            formData.append("mt4_version", version4);
            formData.append("category_name", selectedCategory);
            if (fileMt5) {
                formData.append("expert_mt5", fileMt5);
            }
            if (fileMt4) {
                formData.append("expert_mt4", fileMt4);
            }
            const response = await axios.post(`${url}/admin/update-product`,
                formData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
            if (response.status === 200) {
                window.location.href = "/admin/products";
            }
        } catch (error_response) {
            console.error('Error creation product', error_response);
            console.log(error_response.response.data)
            error_text.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка!";
        }
    };

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const get_product = async () => {
        try {
            const response = await axios.get(`${url}/admin/get-product?product_id=${id}`,
                {withCredentials: true})
            setProduct(response.data);
            if (response.status === 200) {
                setStatus(response.data.enabled);
                setIsDownloadable(response.data.is_downloadable);
                setTitle(response.data.name);
                setServiceKey(response.data.service_key);
                setDescription(response.data.description);
                setSelectedCategory(response.data.category.name);
                setVersion5(response.data.version);
                setVersion4(response.data.mt4_version);
            }
        } catch (error_response) {
            error_text.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка!";
            console.error('Error fetching products', error_response);
        }
    }

    useEffect(() => {
        get_product();
    }, [error_text, url]);

    useEffect(() => {
        setLocationAdmin("admin-product-edit")
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Редактирование продукта</h2>
                <Link to="/admin/products" className="experts-product-back-btn">
                    <i className="fa fa-chevron-left"></i>
                    назад
                </Link>
                <div className="admin-edit-block rf-grey-block">
                    <h3 className="admin-edit-title">Форма редактирования</h3>
                    {product && (
                        <>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Статус:</label>
                                <div className="admin-edit-checkbox">
                                    <label className="switch">
                                        <input type="checkbox" checked={status}
                                               onChange={() => setStatus(!status)}/>
                                        <span className="slider"></span>
                                    </label>
                                    <span className="admin-edit-checkbox-text">Вкл.</span>
                                </div>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Включить скачивание:</label>
                                <div className="admin-edit-checkbox">
                                    <label className="switch">
                                        <input type="checkbox" checked={isDownloadable}
                                               onChange={() => setIsDownloadable(!isDownloadable)}/>
                                        <span className="slider"></span>
                                    </label>
                                    <span className="admin-edit-checkbox-text">Да</span>
                                </div>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Название:</label>
                                <input type="text"
                                       onChange={(event) => setTitle(event.target.value)}
                                       value={title}
                                       required
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Ключ:</label>
                                <input type="text"
                                       value={serviceKey}
                                       onChange={(event) => setServiceKey(event.target.value)}
                                       className="admin-filter-input"/>
                            </div>
                            <MarkdownEditor description={description} setDescription={setDescription}/>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Категория:</label>
                                <button onClick={open_options}
                                        className="admin-filter-input admin-filter-select">{selectedCategory}</button>
                                <div className="method-options-block">
                                    <ul className="method-options-list">
                                        <li className="method-options-item" onClick={() => {
                                            setSelectedCategory("MetricaFX");
                                            open_options();
                                        }}>MetricaFX
                                        </li>
                                        <li className="method-options-item" onClick={() => {
                                            setSelectedCategory("Торговый советник");
                                            open_options();
                                        }}>Торговый советник
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Версия MT5:</label>
                                <input type="text"
                                       required
                                       value={version5}
                                       onChange={(event) => setVersion5(event.target.value)}
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">MT5:</label>
                                <input type="file"
                                       onChange={(event) => setfileMt5(event.target.files[0])}
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Версия MT4:</label>
                                <input type="text"
                                       required
                                       value={version4}
                                       onChange={(event) => setVersion4(event.target.value)}
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">MT4:</label>
                                <input type="file"
                                       onChange={(event) => setfileMt4(event.target.files[0])}
                                       className="admin-filter-input"/>
                            </div>
                            <span className="error"></span>
                            <button onClick={() => update_product()}
                                    className="admin-checking-account-number-btn admin-filter-apply">Сохранить
                            </button>
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default AdminProductEdit;