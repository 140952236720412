import React, {useEffect, useState} from "react";
import "./AdminTariffs.css";
import {Link} from "react-router-dom";
import axios from "axios";

const AdminCategories = ({setLocationAdmin, url}) => {
    const [categories, setCategories] = useState(null);
    const [filterBy, setFilterBy] = useState("");
    const error_text = document.querySelector(".error");
    const [maxPerPage, setMaxPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const reset_search = async () => {
        const admin_filter_input = document.querySelector(".admin-filter-input")
        admin_filter_input.value = "";
        setFilterBy("");
    }

    const get_categories = async () => {
        try {
            const response = await axios.get(`${url}/admin/get-subscribes?offset=${(currentPage - 1) * 10}&limit=${maxPerPage}&filter_by=${filterBy ? filterBy : ""}`,
                {withCredentials: true})
            setCategories(response.data);
        } catch (error_response) {
            error_text.innerText = error_response.response.data.detail;
            console.error('Error fetching accounts', error_response);
        }
    }

    useEffect(() => {
        get_categories();
    }, [url, maxPerPage, currentPage, filterBy]);


    const change_status = async (id) => {
        try {
            const response = await axios.post(`${url}/admin/change-subscribe-status?subscribe_id=${id}`,
                {},
                {withCredentials: true})
            if (response.status === 200) {
                await get_categories();
            }
        } catch (error_response) {
            error_text.innerText = error_response.response.data.detail;
            console.error('Error changing category status', error_response);
        }
    }

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    useEffect(() => {
        setCurrentPage(1);
    }, [filterBy]);

    useEffect(() => {
        setLocationAdmin("admin-tariffs");
    }, [setLocationAdmin]);
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Тарифы</h2>
                <div className="admin-filter rf-grey-block">
                    <div className="admin-filter-row">
                        <h3 className="admin-base-row-title">Фильтр</h3>
                        <div className="admin-filter-btns">
                            <button onClick={() => reset_search()} className="admin-filter-reset">Сбросить</button>
                        </div>
                    </div>
                    <input onChange={(event) => setFilterBy(event.target.value)} type="text" placeholder="Что ищем?"
                           className="admin-filter-input"/>
                </div>
                <div className="admin-filter rf-grey-block">
                    <div className="admin-categories-title-row">
                        <h3 className="admin-base-row-title">Список тарифов</h3>
                        <span style={{display: "flex", justifyContent: "space-between"}}>
                    <div className="method-input-block-base" style={{width: "100px"}}>
                        <div className="method-input-block" onClick={open_options}>
                        <span
                            className="method-input-placeholder">{maxPerPage}</span>
                            <i className="fa fa-chevron-down"></i>
                        </div>
                        <div className="method-options-block">
                            <ul className="method-options-list">
                                <li className="method-options-item"
                                    onClick={() => {
                                        setMaxPerPage(10);
                                        open_options();
                                    }}>10
                                </li>
                                <li className="method-options-item" onClick={() => {
                                    setMaxPerPage(25);
                                    open_options();
                                }}>25
                                </li>
                                <li className="method-options-item" onClick={() => {
                                    setMaxPerPage(50);
                                    open_options();
                                }}>50
                                </li>
                                <li className="method-options-item" onClick={() => {
                                    setMaxPerPage(100);
                                    open_options();
                                }}>100
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
                        <span className="error"></span>
                        <Link to="/admin/tariff/add" className="admin-categories-btn">
                            Добавить
                        </Link>
                    </div>
                    <table className="admin-users-table admin-users-table-categories">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Статус</th>
                            <th>Название</th>
                            <th>Цена</th>
                            <th>Скидка</th>
                            <th>Изображение</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {categories && (
                            categories.subscribes.map((item) => (
                                <tr key={item.id}>
                                    <th>{item.id}</th>
                                    <th>
                                        {item.enabled === true ? (
                                            <button onClick={() => change_status(item.id)} id={`status-${item.id}`}
                                                    className="status-btn green-btn">
                                                Вкл.
                                            </button>
                                        ) : (
                                            <button onClick={() => change_status(item.id)} id={`status-${item.id}`}
                                                    className="status-btn red-btn">Выкл.</button>
                                        )}
                                    </th>
                                    <th>
                                        <div className="admin-users-table-user-info">
                                            <span
                                                className="admin-users-table-user-username">{item.name}</span>
                                            <span className="admin-users-table-user-email">{item.description}</span>
                                        </div>
                                    </th>
                                    <th>$ {item.price / 100}</th>
                                    <th className="green-text">$ {item.sale_price / 100}</th>
                                    <th className="eye-btn">
                                        <div className="admin-users-table-btns">
                                            {item.image ? (
                                                <img style={{minWidth: "150px"}} src={`${url}/static/${item.image.path}`} alt={item.name}/>
                                            ) : "-"}
                                        </div>
                                    </th>
                                    <th className="eye-btn">
                                        <div className="admin-users-table-btns">
                                            <Link to={`/admin/tariff/edit/${item.id}`}
                                                  className="admin-users-table-edit">
                                                <img src="/admin%20icons/pencil.svg" alt="Edit"
                                                     className="admin-users-table-edit-img"/>
                                            </Link>
                                        </div>
                                    </th>
                                </tr>
                            )))}
                        </tbody>
                    </table>
                    {categories && (
                        <div className="pagination">
                        <span className="small-text">
                            Показано с <b>1</b> по <b>10</b> из <b>{categories.count}</b> результатов
                        </span>
                            <ul className="pagination-pages">
                                {categories.count > maxPerPage && (() => {
                                    const totalPages = Math.ceil(categories.count / maxPerPage);
                                    const maxVisiblePages = 5; // количество видимых страниц
                                    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                                    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

                                    const shouldShowLeftDots = startPage > 1;
                                    const shouldShowRightDots = endPage < totalPages;

                                    const paginationItems = [];

                                    // Первая страница
                                    if (shouldShowLeftDots) {
                                        paginationItems.push(
                                            <button
                                                key="first"
                                                className="page-number"
                                                onClick={() => setCurrentPage(1)}
                                            >
                                                1
                                            </button>
                                        );
                                        paginationItems.push(<span key="left-dots" className="dots">...</span>);
                                    }

                                    // Основные страницы
                                    for (let page = startPage; page <= endPage; page++) {
                                        paginationItems.push(
                                            <button
                                                key={page}
                                                className={`page-number ${page === currentPage ? "active" : ""}`}
                                                onClick={() => setCurrentPage(page)}
                                            >
                                                {page}
                                            </button>
                                        );
                                    }

                                    // Последняя страница
                                    if (shouldShowRightDots) {
                                        paginationItems.push(<span key="right-dots" className="dots">...</span>);
                                        paginationItems.push(
                                            <button
                                                key="last"
                                                className="page-number"
                                                onClick={() => setCurrentPage(totalPages)}
                                            >
                                                {totalPages}
                                            </button>
                                        );
                                    }

                                    return paginationItems;
                                })()}
                            </ul>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}
export default AdminCategories;