import React, {useEffect, useState} from "react";
import Notification from "../../components/Notification/Notification";
import "./Wallet.css";
import ReplenishmentWallet from "./ReplenishmentWallet/replenishment-wallet";
import WithdrawalWallet from "./WithdrawalWallet/withdrawal_wallet";
import TransferWallet from "./TransferWallet/transfer_wallet";
import OperationHistory from "./OperationHistory/operationhistory-wallet";
import {useOutletContext} from "react-router-dom";

const Wallet = ({setLocation, url}) => {
    const {userData, balance} = useOutletContext() || {};
    const [walletOperation, setWalletOperation] = useState("Пополнение")

    const {
        wallet_number,
    } = userData || {};

    useEffect(() => {
        setLocation("wallet");
    }, [setLocation]);

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".wallet-operation-btn");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
        });

        event.target.classList.add("active");
    }

    return (
        <>
            <section className="wallet">
                <div className="wallet-column">
                    <Notification/>
                    <div className="wallet-card">
                        <div className="balance-circle">
                            <span className="balance-circle-dollar">$</span>
                        </div>
                        <div className="wallet-card-column">
                            <h2 className="user-balance">Баланс: {balance} $</h2>
                            <span className="user-wallet">Мой кошелек: <span
                                className="user-wallet upper">{wallet_number}</span></span>
                        </div>
                    </div>
                    <ul className="wallet-operations-list">
                        <li className="wallet-operation-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setWalletOperation("Пополнение")
                            }}
                                    className="wallet-operation-btn active">Пополнение
                            </button>
                        </li>
                        <li className="wallet-operation-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setWalletOperation("Вывод")
                            }} className="wallet-operation-btn">Вывод
                            </button>
                        </li>
                        <li className="wallet-operation-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setWalletOperation("Перевод")
                            }} className="wallet-operation-btn">Перевод
                            </button>
                        </li>
                        <li className="wallet-operation-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setWalletOperation("История операций")
                            }} className="wallet-operation-btn">История
                                операций
                            </button>
                        </li>
                    </ul>
                    {walletOperation === "Пополнение" ? (
                        <ReplenishmentWallet userData={userData} url={url}/>
                    ) : walletOperation === "Вывод" ? (
                        <WithdrawalWallet userData={userData} url={url}/>
                    ) : walletOperation === "Перевод" ? (
                        <TransferWallet userData={userData} url={url}/>
                    ) : walletOperation === "История операций" ? (
                        <OperationHistory userData={userData} url={url}/>
                    ) : null}
                </div>
            </section>
        </>
    )
}

export default Wallet;