import React from "react";
import "./TradeCapitalBaseScreen.css";
import {date_formatter} from "../../../../components/formatter";

const TradeCapitalBaseScreen = ({tCAccount}) => {
    return (
        <>
            <div className="trade-capital-base-row">
                <div className="base-hero" style={{height: "max-content"}}>
                    <div className="base-hero-row">
                        <span className="base-hero-title">Номер счёта</span>
                        <span className="base-hero-info">{tCAccount.account_number}</span>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">Подписка до</span>
                        <div className="base-hero-info-block">
                            <span className="base-hero-info">{date_formatter(tCAccount.expires_at)}</span>
                            {new Date(tCAccount.expires_at) < new Date() && (
                                <span
                                    className="red-text">истекла</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="base-hero">
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                    <div className="base-hero-row">
                        <span className="base-hero-title">TC Silver 1.42</span>
                        <button className="base-hero-btn">Скачать</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TradeCapitalBaseScreen;