import React, {useEffect, useState} from 'react';
import "./drawer.css";
import {Link} from "react-router-dom";
import axios from "axios";

const Drawer = ({location, tCAccounts, url}) => {
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        const get_categories = async () => {
            try {
                const response = await axios.get(`${url}/product/get-categories`)
                if (response.status === 200) {
                    setCategories(response.data);
                }
            } catch (error_response) {
                console.error('Error creating withdraw', error_response);
            }
        }
        get_categories();
    }, []);

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".menu-btn");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
            const menu_img = btn.querySelector(".menu-img")
            if (menu_img) {
                menu_img.classList.remove("active");
            }
        });

        event.target.classList.add("active");
        const menu_img = event.target.querySelector(".menu-img")
        menu_img.classList.add("active");
    }

    return (
        <>
            <section className="drawer">
                <div className="container">
                    <div className="drawer-column">
                        <div className="menu">
                            <ul className="menu-list">
                                <li className="menu-list-item">
                                    <Link to="/" className={`menu-btn ${location === "my-account" ? "active" : null}`}
                                          onClick={(event) => active_button(event)}>
                                        <img src={`${location === "my-account" ? "/king-active.svg" : "/king.svg"}`}
                                             alt="Account"
                                             className={`menu-img ${location === "my-account" ? "active" : null}`}></img>
                                        Мой аккаунт
                                    </Link>
                                </li>
                                <li className="menu-list-item">
                                    <Link to="/wallet" className={`menu-btn ${location === "wallet" ? "active" : null}`}
                                          onClick={(event) => active_button(event)}>
                                        <img src={`${location === "wallet" ? "/wallet-active.svg" : "/wallet.svg"}`}
                                             alt="Wallet"
                                             className={`menu-img ${location === "wallet" ? "active" : null}`}></img>
                                        Мой кошелёк
                                    </Link>
                                </li>
                                {categories && (
                                    <>
                                        {categories.some(item => item.key === "expert") && (
                                            <li className="menu-list-item">
                                                <Link to="/experts"
                                                      className={`menu-btn ${location === "experts" || location === "experts-product" || location === "experts-activation" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img
                                                        src={`${location === "experts" || location === "experts-product" || location === "experts-buy" || location === "experts-activation" ? "/experts-active.svg" : "/experts.svg"}`}
                                                        alt="Experts"
                                                        className={`menu-img ${location === "experts" || location === "experts-product" || location === "experts-activation" ? "active" : null}`}></img>
                                                    Торговые системы
                                                </Link>
                                            </li>
                                        )}
                                        {categories.some(item => item.key === "copying") && (
                                            <li className="menu-list-item">
                                                <Link to="/copying"
                                                      className={`menu-btn ${location === "copying" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img
                                                        src={`${location === "copying" ? "/copy-active.svg" : "/copy-grey.svg"}`}
                                                        alt="Experts"
                                                        className={`menu-img ${location === "copying" ? "active" : null}`}></img>
                                                    Копирование
                                                </Link>
                                            </li>
                                        )}
                                        {categories.some(item => item.key === "metrica") && (
                                            <li className="menu-list-item">
                                                <Link to="/metricafx"
                                                      className={`menu-btn ${location === "metricafx" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img
                                                        src={`${location === "metricafx" ? "/metricafx-active.svg" : "/metricafx.svg"}`}
                                                        alt="MetricaFX"
                                                        className={`menu-img ${location === "metricafx" ? "active" : null}`}></img>
                                                    MetricaFX
                                                </Link>
                                            </li>
                                        )}
                                    </>
                                )}
                                {tCAccounts && (
                                    <li className="menu-list-item">
                                        <Link to="/trade-capital"
                                              className={`menu-btn ${location === "trade-capital" || location === "trade-capital/account" ? "active" : null}`}
                                              onClick={(event) => active_button(event)}>
                                            <img
                                                src={`${location === "trade-capital" || location === "trade-capital/account" ? "/circle.svg" : "/circle-grey.svg"}`}
                                                alt="Trade Capital"
                                                className={`menu-img ${location === "trade-capital" || location === "trade-capital/account" ? "active" : null}`}></img>
                                            Trade Capital
                                        </Link>
                                    </li>
                                )}
                                <li className="menu-list-item">
                                    <Link to="/partners"
                                          className={`menu-btn ${location === "partners" || location === "partners_invite" ? "active" : null}`}
                                          onClick={(event) => active_button(event)}>
                                        <img
                                            src={`${location === "partners" || location === "partners_invite" ? "/partners-active.svg" : "/partners.svg"}`}
                                            alt="Partners"
                                            className={`menu-img ${location === "partners" || location === "partners_invite" ? "active" : null}`}></img>
                                        Мои партнёры
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="invite-friend">
                            <div className="invite-friend-column">
                                <img src="/invite-friend.svg" alt="Invite Friend Logo" className="invite-friend-img"/>
                                <h2 className="invite-friend-title">Пригласите друга</h2>
                                <span className="invite-friend-description">Пригласите друга в свою команду и развивайтесь вместе с нашей <Link
                                    to="/partners/invite"
                                    className="invite-friend-link">партнёрской программой</Link></span>
                                <Link to="/partners/invite" className="invite-friend-button">Пригласить</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Drawer;