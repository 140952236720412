import React, {useEffect, useState} from 'react';
import "./StatisticsScreen.css";
import axios from "axios";
import {useParams} from "react-router-dom";

const StatisticsScreen = ({url, account}) => {
    const {account_number} = useParams();
    const [metricaData, setMetricaData] = useState(null);

    const {
        account_balance,
        account_equity,
        account_margin_level,
        profit_total_cur,
        profit_total_per,
        profit_today_cur,
        profit_today_per,
        profit_yesterday_cur,
        profit_yesterday_per,
        profit_week_cur,
        profit_week_per,
        profit_month_cur,
        profit_month_per,
    } = metricaData || {};

    const {} = account || {}

    useEffect(() => {
        const getMetricaData = async () => {
            try {
                const response = await axios.get(`${url}/report/get-report?account_number=${account_number}`, {withCredentials: true});
                if (response.data) {
                    setMetricaData(response.data);
                } else {
                    setMetricaData(null);
                }
            } catch (error_response) {
                console.error('Error fetching metrica data', error_response);
            }
        }
        getMetricaData()
    }, [account_number, url]);

    return (
        <>
            {account && metricaData ? (
                <div className="statistic-row">
                    <div className="statistic-column">
                        <ul className="statistic-column-info">
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Баланс счёта</span>
                                <span>{account_balance}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Эквити</span>
                                <span>{account_equity}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Маржа</span>
                                <span>{account_margin_level}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Профит сегодня</span>
                                <span>{profit_today_cur} ({profit_today_per}%)</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Профит вчера</span>
                                <span>{profit_yesterday_cur} ({profit_yesterday_per}%)</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Профит за неделю</span>
                                <span>{profit_week_cur} ({profit_week_per}%)</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Профит за месяц</span>
                                <span>{profit_month_cur} ({profit_month_per}%)</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Общий профит</span>
                                <span>{profit_total_cur} ({profit_total_per}%)</span>
                            </li>
                        </ul>
                    </div>
                    <div className="statistic-column">
                        <ul className="statistic-column-info">
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Торговая система</span>
                                <span>{account.product.name}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Версия</span>
                                <span>{account.product.version}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Имя аккаунта</span>
                                <span>{metricaData.account_name}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Биржа</span>
                                <span>{metricaData.account_company}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Сервер</span>
                                <span>{metricaData.account_server}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Валюта</span>
                                <span>{metricaData.account_currency}</span>
                            </li>
                            <li className="statistic-column-row">
                                <span className="statistic-column-title">Торговое плечо</span>
                                <span>{metricaData.account_leverage}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            ) : (
                <div className="base-hero statistics">
                <span className="statistics-error-text">
                    <b>Внимание</b>
                    <br/>
                    На текущий момент нет данных для отображения.
                </span>
                    <span className="statistics-error-text">
                    Торговая статистика в личном кабинете обновляется раз в сутки.
                    <br/>
                    Для более оперативного получения статистики, воспользуйтесь сервисом MetricaFX.
                </span>
                </div>
            )}
        </>
    )
}

export default StatisticsScreen