import React, {useEffect, useState} from "react";
import "./AdminUsers.css";
import {Link} from "react-router-dom";
import axios from "axios";
import {date_formatter} from "../../../components/formatter";

const AdminUsers = ({setLocationAdmin, url}) => {
    const [users, setUsers] = useState(null);
    const [filterBy, setFilterBy] = useState("");
    const error_text = document.querySelector(".error");
    const [maxPerPage, setMaxPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const reset_search = async () => {
        const admin_filter_input = document.querySelector(".admin-filter-input")
        admin_filter_input.value = "";
        setFilterBy("");
    }

    const admin_login = async (user_id) => {
        try {
            const response = await axios.post(`${url}/admin/login?user_id=${user_id}`,
                {},
                {withCredentials: true})
            if (response.status === 200) {
                window.open("/", '_blank');
            }
        } catch (error_response) {
            error_text.innerText = error_response.response.data.detail;
            console.error('Error fetching users', error_response);
        }
    }

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    useEffect(() => {
        setCurrentPage(1);
    }, [filterBy, ]);

    useEffect(() => {
        const get_users = async () => {
            try {
                const response = await axios.get(`${url}/admin/get-users?offset=${(currentPage - 1) * 10}&limit=${maxPerPage}&filter_by=${filterBy ? filterBy : ""}`,
                    {withCredentials: true})
                setUsers(response.data);
            } catch (error_response) {
                error_text.innerText = error_response.response.data.detail;
                console.error('Error changing account number', error_response);
            }
        }
        get_users();
    }, [url, currentPage, filterBy, maxPerPage]);

    useEffect(() => {
        setLocationAdmin("admin-users");
    }, [setLocationAdmin]);
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Пользователи</h2>
                <div className="admin-filter rf-grey-block">
                    <div className="admin-filter-row">
                        <h3 className="admin-base-row-title">Фильтр</h3>
                        <div className="admin-filter-btns">
                            <button onClick={() => reset_search()} className="admin-filter-reset">Сбросить</button>
                        </div>
                    </div>
                    <input onChange={(event) => setFilterBy(event.target.value)} type="text" placeholder="Что ищем?"
                           className="admin-filter-input"/>
                </div>
                <div className="admin-filter rf-grey-block">
                    <span style={{display: "flex", justifyContent: "space-between"}}>
                        <h3 className="admin-base-row-title">Список пользователей</h3>
                        <div className="method-input-block-base" style={{width: "100px"}}>
                                <div className="method-input-block" onClick={open_options}>
                                <span
                                    className="method-input-placeholder">{maxPerPage}</span>
                                    <i className="fa fa-chevron-down"></i>
                                </div>
                                <div className="method-options-block">
                                    <ul className="method-options-list">
                                        <li className="method-options-item"
                                            onClick={() => {
                                                setMaxPerPage(10);
                                                open_options();
                                            }}>10
                                        </li>
                                        <li className="method-options-item" onClick={() => {
                                            setMaxPerPage(25);
                                            open_options();
                                        }}>25
                                        </li>
                                        <li className="method-options-item" onClick={() => {
                                            setMaxPerPage(50);
                                            open_options();
                                        }}>50
                                        </li>
                                        <li className="method-options-item" onClick={() => {
                                            setMaxPerPage(100);
                                            open_options();
                                        }}>100
                                        </li>
                                    </ul>
                                </div>
                            </div>
                    </span>
                    <span className="error"></span>
                    <table className="admin-users-table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Пользователь</th>
                            <th>Спонсор</th>
                            <th>Кошелёк</th>
                            <th>Есть ТС</th>
                            <th>Телеграм</th>
                            <th>Роль</th>
                            <th>Регистрация</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {users && (
                            users.users.map((item) => (
                                <tr key={item.user.id}>
                                    <th>{item.user.id}</th>
                                    <th>
                                        <div className="admin-users-table-user-row">
                                            <img src="/admin%20icons/avatar2.jpg" alt="Avatar"
                                                 className="admin-users-table-user-avatar"/>
                                            <div className="admin-users-table-user-info">
                                                <span className="admin-users-table-user-username">{item.user.name}</span>
                                                <span
                                                    className="admin-users-table-user-email">{item.user.email}</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="admin-users-table-user-row">
                                                <img src="/admin%20icons/avatar2.jpg" alt="Avatar"
                                                     className="admin-users-table-user-avatar"/>
                                                <div className="admin-users-table-user-info">
                                                    <span className="admin-users-table-user-username">{item.referal && item.referal.name}</span>
                                                    <span
                                                        className="admin-users-table-user-email">{item.referal && item.referal.email}</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="admin-users-table-user-info">
                                                <span className="admin-users-table-user-email">Баланс: ${item.user.balance / 100}</span>
                                                <span
                                                    className="admin-users-table-user-email">Номер: {item.user.wallet_number}</span>
                                            </div>
                                        </th>
                                        <th>
                                            <span className="admin-users-table-user-email">{item.tc_user ? "Да" : "Нет"}</span>
                                        </th>
                                        <th>
                                            <div className="admin-users-table-user-info">
                                                <span
                                                    className="admin-users-table-user-tg">{item.user.telegram_username ? `@${item.user.telegram_username}` : "-"}</span>
                                                {/*<span className="admin-users-table-user-email">ID: </span>*/}
                                            </div>
                                        </th>
                                        <th>
                                            <span className="admin-users-table-user-email">{item.user.role}</span>
                                        </th>
                                        <th>
                                            <span
                                                className="admin-users-table-user-email">{date_formatter(item.user.created_at)}</span>
                                        </th>
                                        <th>
                                            <div className="admin-users-table-btns">
                                                <Link to="#" className="admin-users-table-edit">
                                                    <img src="/admin%20icons/pencil.svg" alt="Edit"
                                                         className="admin-users-table-edit-img"/>
                                                </Link>
                                                <button onClick={() => admin_login(item.user.id)}
                                                        className="admin-users-table-edit">
                                                    <img src="/admin%20icons/door-2.svg" alt="Login"
                                                         className="admin-users-table-edit-img"/>
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                )
                            )
                        )}
                        </tbody>
                    </table>
                    {users && (
                        <div className="pagination">
                            <span className="small-text">
                                Показано с <b>1</b> по <b>10</b> из <b>{users.count}</b> результатов
                            </span>
                            <ul className="pagination-pages">
                                {users.count > maxPerPage && (() => {
                                    const totalPages = Math.ceil(users.count / maxPerPage);
                                    const maxVisiblePages = 5; // количество видимых страниц
                                    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                                    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

                                    const shouldShowLeftDots = startPage > 1;
                                    const shouldShowRightDots = endPage < totalPages;

                                    const paginationItems = [];

                                    // Первая страница
                                    if (shouldShowLeftDots) {
                                        paginationItems.push(
                                            <button
                                                key="first"
                                                className="page-number"
                                                onClick={() => setCurrentPage(1)}
                                            >
                                                1
                                            </button>
                                        );
                                        paginationItems.push(<span key="left-dots" className="dots">...</span>);
                                    }

                                    // Основные страницы
                                    for (let page = startPage; page <= endPage; page++) {
                                        paginationItems.push(
                                            <button
                                                key={page}
                                                className={`page-number ${page === currentPage ? "active" : ""}`}
                                                onClick={() => setCurrentPage(page)}
                                            >
                                                {page}
                                            </button>
                                        );
                                    }

                                    // Последняя страница
                                    if (shouldShowRightDots) {
                                        paginationItems.push(<span key="right-dots" className="dots">...</span>);
                                        paginationItems.push(
                                            <button
                                                key="last"
                                                className="page-number"
                                                onClick={() => setCurrentPage(totalPages)}
                                            >
                                                {totalPages}
                                            </button>
                                        );
                                    }

                                    return paginationItems;
                                })()}
                            </ul>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default AdminUsers