import React, {useState} from "react";
import "./transfer_wallet.css";
import {Link} from "react-router-dom";
import axios from "axios";

const TransferWallet = ({ url }) => {
    const [cryptoWallet, setCryptoWallet] = useState("");
    const [transferAmount, setTransferAmount] = useState(0.0);
    const [checkbox, setCheckbox] = useState(false);

    const transfer_money = async () => {
        const error_block = document.querySelector(".method-text-error.input1");
        const error_block_2 = document.querySelector(".method-text-error.input2");
        const error_checkbox = document.querySelector(".method-text-error.checkbox-error");
        const notification_text = document.querySelector(".success-notification");

        let hasErrors = false;

        notification_text.innerText = "";

        // Проверка поля cryptowallet
        if (cryptoWallet.length < 11 || cryptoWallet.slice(0, 2).toUpperCase() !== "RF") {
            error_block.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_2) {
            error_block.innerText = "";
        }

        // Проверка поля cryptocurrency
        if (transferAmount < 1) {
            error_block_2.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_2) {
            error_block_2.innerText = "";
        }

        // Проверка чекбокса
        if (!checkbox) {
            error_checkbox.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_checkbox.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/wallet/transfer-money?recipient_account_number=${cryptoWallet}&amount=${transferAmount}`,
                    {},
                    {withCredentials: true})
                if (response.status === 200) {
                    notification_text.innerText = response.data.detail;
                }
            } catch (error_response) {
                console.error('Error sending money', error_response);
                console.log(error_response.response.data)
                error_checkbox.innerText = error_response.response.data.detail ? error_response.response.data.detail : "Неизвестная ошибка!";
            }
        }
    };

    return (
        <>
            <div className="method-of-replenishment">
                <div className="method-of-replenishment-first-column">
                    <div className="wallet-address">
                        <span className="method-text">Адрес кошелька получателя</span>
                        <input onChange={(event) => setCryptoWallet(event.target.value)} type="text" placeholder="RFXXXXXXXXX"
                               className="method-input-block-base"/>
                        <span className="method-text-error input1"></span>
                    </div>
                    <div className="withdrawal-amount">
                        <span className="method-text">Сумма перевода</span>
                        <input onChange={(event) => setTransferAmount(event.target.value)} type="number" placeholder="0.00"
                               className="method-input-block-base"/>
                        <span className="method-text-error input2"></span>
                    </div>
                    <div className="checkbox-block">
                        <div className="checkbox-block-row">
                            <input type="checkbox" className="checkbox" checked={checkbox}
                                   onChange={(event) => setCheckbox(event.target.checked)}/>
                            <span className="checkbox-text">Я принимаю <Link to="#" className="invite-friend-link">условия перевода</Link> средств между пользователями.</span>
                        </div>
                        <span className="method-text-error checkbox-error"></span>
                        <span className="success-notification" style={{color: "green", fontWeight: "800"}}></span>
                    </div>
                    <button className="method-of-replenishment-btn" onClick={transfer_money}>Перевести
                    </button>
                </div>
            </div>
        </>
    )
}

export default TransferWallet;