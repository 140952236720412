import React, {useEffect, useState} from 'react';
import "./ReplacingSystemScreen.css";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

const ReplacingSystemScreen = ({ url }) => {
    const [advisorsType, setAdvisorsType] = useState("conservative");
    const [advisor, setAdvisor] = useState("NOVA_CONSERVATIVE");
    const [checkbox, setCheckbox] = useState(false);
    const { account_number } = useParams();

    const change_system = async () => {
        const error_checkbox = document.querySelector(".method-text-error.checkbox-error");

        let hasErrors = false;

        // Проверка чекбокса
        if (!checkbox) {
            error_checkbox.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_checkbox.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/account/change-system?account_number=${account_number}&new_system=${advisor}`,
                    {},
                    {withCredentials: true})
                if (response.status === 200) {
                    window.location = "/experts";
                }
            } catch (error_response) {
                console.error('Error fetching tariffs data', error_response);
                console.log(error_response);
                error_checkbox.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка.";
            }
        }
    };

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".types-of-advisors-item");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
        });

        event.target.classList.add("active");
    }

    const active_button_advisor = (event) => {
        const listBtns = document.querySelectorAll(".advisors-item");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
        });

        event.target.classList.add("active");
    }

    useEffect(() => {
        const listAdvisorBtns = document.querySelectorAll(".advisors-item");
        const advisor_name = listAdvisorBtns[0].innerText;
        if (advisor_name === "Nova Conservative") {
            setAdvisor("NOVA_CONSERVATIVE");
        } else if (advisor_name === "Nova Conservative+") {
            setAdvisor("NOVA_CONSERVATIVE_PLUS");
        } else if (advisor_name === "Nova Medium") {
            setAdvisor("NOVA_MEDIUM");
        } else if (advisor_name === "Nova Medium+") {
            setAdvisor("NOVA_MEDIUM_PLUS");
        } else if (advisor_name === "Spider Pro") {
            setAdvisor("SPIDER_PRO");
        } else if (advisor_name === "Spider Lite") {
            setAdvisor("SPIDER_LITE");
        }
        listAdvisorBtns.forEach((btn) => {
            btn.classList.remove("active");
        });
        listAdvisorBtns[0].classList.add("active");
    }, [advisorsType]);

    return (
        <>
            <div className="replacing-system-column">
                <div className="advisor-selection-block">
                    <div className="types-of-advisors">
                        <h4 className="types-of-advisors-title">Типы советников</h4>
                        <ul className="types-of-advisors-list">
                            <li className="types-of-advisors-item active" onClick={(event) => {
                                setAdvisorsType("conservative");
                                active_button(event);
                            }}>Консервативные
                            </li>
                            <li className="types-of-advisors-item" onClick={(event) => {
                                setAdvisorsType("moderate");
                                active_button(event);
                            }}>Умеренные
                            </li>
                            <li className="types-of-advisors-item" onClick={(event) => {
                                setAdvisorsType("aggressive");
                                active_button(event);
                            }}>Агрессивные
                            </li>
                        </ul>
                    </div>
                    <div className="advisors">
                        <h4 className="types-of-advisors-title">Советники</h4>
                        <ul className="types-of-advisors-list">
                            {advisorsType === "conservative" ? (
                                <li className="advisors-item active" onClick={(event) => {
                                    setAdvisor("NOVA_CONSERVATIVE");
                                    active_button_advisor(event);
                                }}>Nova Conservative</li>
                            ) : advisorsType === "moderate" ? (
                                <>
                                    <li className="advisors-item active" onClick={(event) => {
                                        setAdvisor("NOVA_MEDIUM");
                                        active_button_advisor(event);
                                        }}>Nova Medium
                                    </li>
                                    <li className="advisors-item active" onClick={(event) => {
                                        setAdvisor("NOVA_CONSERVATIVE_PLUS");
                                        active_button_advisor(event);
                                        }}>Nova Conservative+
                                    </li>
                                </>
                            ) : advisorsType === "aggressive" && (
                                <>
                                    <li className="advisors-item active" onClick={(event) => {
                                        setAdvisor("SPIDER_PRO");
                                        active_button_advisor(event);
                                    }}>Spider Pro
                                    </li>
                                    <li className="advisors-item" onClick={(event) => {
                                        setAdvisor("SPIDER_LITE");
                                        active_button_advisor(event);
                                    }}>Spider Lite
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="checkbox-block">
                    <div className="checkbox-block-row">
                        <input type="checkbox" className="checkbox" checked={checkbox}
                               onChange={(event) => setCheckbox(event.target.checked)}/>
                        <span className="checkbox-text">Я принимаю <Link to="#" className="invite-friend-link">условия замены</Link> торгового советника.</span>
                    </div>
                    <span className="method-text-error checkbox-error"></span>
                </div>
                <button className="method-of-replenishment-btn" onClick={change_system}>Заменить</button>
            </div>
        </>
    )
}

export default ReplacingSystemScreen