import React, {useEffect, useState} from "react";
import "./AdminProductFileEdit.css";
import {Link} from "react-router-dom";

const AdminProductFileEdit = ({setLocationAdmin, url}) => {
    const [currentVersion, setCurrentVersion] = useState(true)
    const [version, setVersion] = useState("3.00")
    const [selectedMT, setSelectedMT] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleRadioChange = (event) => {
        setSelectedMT(event.target.value); // Устанавливаем выбранное значение
    };

    useEffect(() => {
        setLocationAdmin("admin-product-file-edit")
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Изменение файла продукта</h2>
                <Link to="/admin/product/22" className="experts-product-back-btn">
                    <i className="fa fa-chevron-left"></i>
                    назад
                </Link>
                <div className="admin-edit-block rf-grey-block">
                    <h3 className="admin-edit-title">Данные файла</h3>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Текущая версия:</label>
                        <div className="admin-edit-checkbox">
                            <label className="switch">
                                <input type="checkbox" checked={currentVersion} onChange={() => setCurrentVersion(!currentVersion)}/>
                                <span className="slider"></span>
                            </label>
                            <span className="admin-edit-checkbox-text">Да</span>
                        </div>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Версия:</label>
                        <input type="text" required value={version} onChange={(event) => setVersion(event.target.value)} className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Для МТ4:</label>
                        <div className="admin-edit-checkbox">
                            <label className="switch">
                                <input type="radio" value="MT4" checked={selectedMT === 'MT4'}
                                       onChange={handleRadioChange}/>
                                <span className="slider"></span>
                            </label>
                            <span className="admin-edit-checkbox-text">Вкл.</span>
                        </div>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Для МТ5:</label>
                        <div className="admin-edit-checkbox">
                            <label className="switch">
                                <input type="radio" value="MT5" checked={selectedMT === 'MT5'}
                                       onChange={handleRadioChange}/>
                                <span className="slider"></span>
                            </label>
                            <span className="admin-edit-checkbox-text">Вкл.</span>
                        </div>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Файл:</label>
                        <input type="file" className="admin-file-input"/>
                        {selectedFile && <span className="file-name">Выбранный файл: {selectedFile.name}</span>}
                    </div>
                </div>
                <button className="admin-checking-account-number-btn admin-filter-apply">Сохранить</button>
            </section>
        </>
    )
}

export default AdminProductFileEdit