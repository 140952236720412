import React, {useEffect, useState} from "react";
import "./AdminReplacementMail.css";
import axios from "axios";

const AdminReplacementMail = ({setLocationAdmin, url}) => {
    const [oldEmail, setOldEmail] = useState("")
    const [newEmail, setNewEmail] = useState("")

    const change_email = async () => {
        let hasErrors = false;
        const error_text = document.querySelector(".error");
        const notification_text = document.querySelector(".notification");

        error_text.innerText = "";
        notification_text.innerText = "";

        if (oldEmail.length < 6 && !newEmail.includes("@")) {
            error_text.innerText = "Необходимо ввести старую почту пользователя!";
            return null;
        }

        if (newEmail.length < 6 && !newEmail.includes("@")) {
            error_text.innerText = "Необходимо ввести новую почту пользователя!";
            return null;
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/admin/change-email?old_email=${oldEmail}&new_email=${newEmail}`,
                    {},
                    {withCredentials: true})
                notification_text.innerText = response.data.detail;
            } catch (error_response) {
                if (error_response.response.status === 422) {
                    error_text.innerText = "Почта введена неверно!"
                } else {
                    error_text.innerText = error_response.response.data.detail;
                }
                console.error('Error changing email', error_response);
            }
        }
    };

    useEffect(() => {
        setLocationAdmin("admin-replacement-mail")
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Замена почты</h2>
                <div className="admin-checking-account-number-warning">
                    <h3 className="admin-checking-account-number-warning-title">Вводя данные, будь внимателен!</h3>
                    <span className="admin-checking-account-number-warning-info">Не допускай ввод лишних символов, пробелов и прочего!</span>
                </div>
                <div className="admin-checking-account-number-form">
                    <span className="error"></span>
                    <span className="notification" style={{color: "green", fontWeight: "800"}}></span>
                    <label>Старый Email:</label>
                    <input type="text" required value={oldEmail} onChange={(event) => setOldEmail(event.target.value)}
                           className="admin-filter-input"/>
                </div>
                <div className="admin-checking-account-number-form">
                    <label>Новый Email:</label>
                    <input type="text" required value={newEmail} onChange={(event) => setNewEmail(event.target.value)} className="admin-filter-input"/>
                </div>
                <button onClick={() => change_email()} className="admin-checking-account-number-btn admin-filter-apply">Заменить</button>
            </section>
        </>
    )
}

export default AdminReplacementMail;