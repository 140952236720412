import React, {useEffect, useState} from "react";
import "./AdminReplacingAccountNumber.css";
import axios from "axios";

const AdminReplacingAccountNumber = ({setLocationAdmin, url}) => {
    const [oldAccountNumber, setOldAccountNumber] = useState("")
    const [newAccountNumber, setNewAccountNumber] = useState("")

    const change_account_number = async () => {
        let hasErrors = false;
        const error_text = document.querySelector(".error");
        const notification_text = document.querySelector(".notification");

        error_text.innerText = "";
        notification_text.innerText = "";

        if (oldAccountNumber.length < 6) {
            error_text.innerText = "Необходимо ввести старый номер счета пользователя!";
            return null;
        }

        if (newAccountNumber.length < 6) {
            error_text.innerText = "Необходимо ввести новый номер счета пользователя!";
            return null;
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/admin/change-account-number?old_account_number=${oldAccountNumber}&new_account_number=${newAccountNumber}`,
                    {},
                    {withCredentials: true})
                notification_text.innerText = response.data.detail;
            } catch (error_response) {
                if (error_response.response.status === 422) {
                    error_text.innerText = "Счёт введен неверно!"
                } else {
                    error_text.innerText = error_response.response.data.detail;
                }
                console.error('Error changing account number', error_response);
            }
        }
    };

    useEffect(() => {
        setLocationAdmin("admin-replacing-account-number")
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Замена номера счёта</h2>
                <div className="admin-checking-account-number-warning">
                    <h3 className="admin-checking-account-number-warning-title">Вводя данные, будь внимателен!</h3>
                    <span className="admin-checking-account-number-warning-info">Не допускай ввод лишних символов, пробелов и прочего!</span>
                </div>
                <div className="admin-checking-account-number-form">
                    <span className="error"></span>
                    <span className="notification" style={{color: "green", fontWeight: "800"}}></span>
                    <label>Старый номер счёта:</label>
                    <input type="text" required value={oldAccountNumber}
                           onChange={(event) => setOldAccountNumber(event.target.value)}
                           className="admin-filter-input"/>
                </div>
                <div className="admin-checking-account-number-form">
                    <label>Новый номер счёта:</label>
                    <input type="text" required value={newAccountNumber} onChange={(event) => setNewAccountNumber(event.target.value)} className="admin-filter-input"/>
                </div>
                <button onClick={() => change_account_number()} className="admin-checking-account-number-btn admin-filter-apply">Заменить</button>
            </section>
        </>
    )
}

export default AdminReplacingAccountNumber;