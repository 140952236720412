import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Notification from "../../components/Notification/Notification";
import "./Partners.css";
import axios from "axios";
import {date_formatter} from "../../components/formatter";

const Partners = ({setLocation, url}) => {
    const [selectedLevel, setSelectedLevel] = useState("lvl1")
    const [friendData, setFriendData] = useState(null)
    const maxPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".partners-level-btn");
        setCurrentPage(1);

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
        });

        event.target.classList.add("active");
    }

    useEffect(() => {
        setLocation("partners");
    }, [setLocation]);

    useEffect(() => {
        const getFriends = async () => {
            try {
                const response = await axios.get(`${url}/user/get-friends?offset=${(currentPage - 1) * 10}`, {withCredentials: true});
                setFriendData(response.data);
            } catch (error_response) {
                console.error('Error fetching friends data', error_response);
            }
        }
        getFriends()
    }, [currentPage, url]);

    return (
        <>
            <section className="partners">
                <div className="partners-column">
                    <Notification/>
                    <div className="hero partners">
                        <div className="hero-row partners">
                            <div className="partners-explanation">
                                <h2 className="partners-title">Партнёрская программа</h2>
                                <span className="partners-description">
                                    Пригласите своих друзей и получите<br/>ещё больше прибыли работая вместе
                                </span>
                                <Link to="/partners/invite" className="partners-link">Пригласить друга</Link>
                            </div>
                            <img src="/partners-active.svg" alt="Partners" className="partners-img"/>
                        </div>
                    </div>
                    <ul className="partners-level-list">
                        <li className="partners-level-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setSelectedLevel("lvl1")
                            }}
                                    className="partners-level-btn active">1 уровень
                            </button>
                        </li>
                        <li className="partners-level-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setSelectedLevel("lvl2")
                            }} className="partners-level-btn">2 уровень
                            </button>
                        </li>
                        <li className="partners-level-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setSelectedLevel("lvl3")
                            }} className="partners-level-btn">3 уровень
                            </button>
                        </li>
                    </ul>
                    <table className="partners-table">
                        <thead>
                        <tr className="partners-table-head">
                            <th>Никнейм</th>
                            <th>Партнеры</th>
                            {selectedLevel === "lvl1" ? (
                                <th>Телеграм</th>
                            ) : null}
                            <th>Дата регистрации</th>
                        </tr>
                        </thead>
                        <tbody>
                        {friendData ? (
                            friendData.friends_first_level && selectedLevel === "lvl1" ? (
                                friendData.friends_first_level.map((friend) => (
                                    <tr key={friend.id} className={`partners-table-row ${currentPage === 1 ? (friendData.friends_first_level[0].id === friend.id ? "first" : friendData.friends_first_level[1].id === friend.id ? "second" : friendData.friends_first_level[2].id === friend.id ? "third" : null) : null}`}>
                                        <td>{friend.name}</td>
                                        <td>{friend.partners_count}</td>
                                        <td>
                                            {friend.telegram_username ? (
                                                <Link to={`https://t.me/${friend.telegram_username.replace("@", "")}`}
                                                      className="partners-table-link"><span
                                                    className="yellow-text">Написать</span></Link>
                                            ) : "Нет данных"}
                                        </td>
                                        <td>{date_formatter(friend.created_at)}</td>
                                    </tr>
                                ))
                            ) : friendData.friends_second_level && selectedLevel === "lvl2" ? (
                                friendData.friends_second_level.map((friend) => (
                                    <tr key={friend.id} className="partners-table-row">
                                        <td>{friend.name}</td>
                                        <td>{friend.partners_count}</td>
                                        <td>{date_formatter(friend.created_at)}</td>
                                    </tr>
                                ))
                            ) : friendData.friends_third_level && selectedLevel === "lvl3" ? (
                                friendData.friends_third_level.map((friend) => (
                                    <tr key={friend.id} className="partners-table-row">
                                        <td>{friend.name}</td>
                                        <td>{friend.partners_count}</td>
                                        <td>{date_formatter(friend.created_at)}</td>
                                    </tr>
                                ))
                            ) : null
                        ) : null}
                        </tbody>
                    </table>
                    {friendData ? (
                        <div className="pagination">
                            <span
                                className="small-text">Показано с <b>1</b> по <b>10</b> из <b>{selectedLevel === "lvl1" ? friendData.counts.first_level : selectedLevel === "lvl2" ? friendData.counts.second_level : selectedLevel === "lvl3" ? friendData.counts.third_level : ""}</b> результатов
                            </span>
                            <ul className="pagination-pages">
                                {selectedLevel === "lvl1" ? (
                                    <>
                                        {friendData.counts.first_level > maxPerPage ? (
                                            Array.from({length: Math.ceil(friendData.counts.first_level / maxPerPage)}).map((id, index) => {
                                                let page;
                                                if (currentPage >= (Math.ceil(friendData.counts.first_level / maxPerPage)) - ((friendData.counts.first_level / maxPerPage) - 1)) {
                                                    // Если текущая страница ближе к концу
                                                    page = (Math.ceil(friendData.counts.first_level / maxPerPage)) - ((Math.ceil(friendData.counts.first_level / maxPerPage) - 1)) + index;
                                                } else {
                                                    page = currentPage + index;
                                                }
                                                return (
                                                    page <= (Math.ceil(friendData.counts.first_level / maxPerPage)) && page > 0 && (
                                                        <button
                                                            key={page}
                                                            className={`page-number ${page === currentPage ? "active" : ""}`}
                                                            onClick={() => setCurrentPage(page)}>
                                                            {page ? (
                                                                <b key={id} className="pagination-page">{page}</b>
                                                            ) : null}
                                                        </button>
                                                    )
                                                );
                                            })
                                        ) : null}
                                    < />
                                ) : selectedLevel === "lvl2" ? (
                                    <>
                                        {friendData.counts.second_level > maxPerPage ? (
                                            Array.from({length: Math.ceil(friendData.counts.second_level / maxPerPage)}).map((id, index) => {
                                                let page;
                                                if (currentPage >= (Math.ceil(friendData.counts.second_level / maxPerPage)) - ((friendData.counts.second_level / maxPerPage) - 1)) {
                                                    // Если текущая страница ближе к концу
                                                    page = (Math.ceil(friendData.counts.second_level / maxPerPage)) - ((Math.ceil(friendData.counts.second_level / maxPerPage) - 1)) + index;
                                                } else {
                                                    page = currentPage + index;
                                                }
                                                return (
                                                    page <= (Math.ceil(friendData.counts.second_level / maxPerPage)) && page > 0 && (
                                                        <button
                                                            key={page}
                                                            className={`page-number ${page === currentPage ? "active" : ""}`}
                                                            onClick={() => setCurrentPage(page)}>
                                                            {page ? (
                                                                <b key={id}
                                                                   className="pagination-page">{page}</b>
                                                            ) : null}
                                                        </button>
                                                    )
                                                );
                                            })
                                        ) : null}
                                    < />
                                ) : selectedLevel === "lvl3" ? (
                                    <>
                                        {friendData.counts.third_level > maxPerPage ? (
                                            Array.from({length: Math.ceil(friendData.counts.third_level / maxPerPage)}).map((id, index) => {
                                                let page;
                                                if (currentPage >= (Math.ceil(friendData.counts.third_level / maxPerPage)) - ((friendData.counts.third_level / maxPerPage) - 1)) {
                                                    // Если текущая страница ближе к концу
                                                    page = (Math.ceil(friendData.counts.third_level / maxPerPage)) - ((Math.ceil(friendData.counts.third_level / maxPerPage) - 1)) + index;
                                                } else {
                                                    page = currentPage + index;
                                                }
                                                return (
                                                    page <= (Math.ceil(friendData.counts.third_level / maxPerPage)) && page > 0 && (
                                                        <button
                                                            key={page}
                                                            className={`page-number ${page === currentPage ? "active" : ""}`}
                                                            onClick={() => setCurrentPage(page)}>
                                                            {page ? (
                                                                <b key={id}
                                                                   className="pagination-page">{page}</b>
                                                            ) : null}
                                                        </button>
                                                    )
                                                );
                                            })
                                        ) : null}
                                    < />
                                ) : null}
                            </ul>
                        </div>
                    ) : null}
                </div>
            </section>
        </>
    )
}

export default Partners;