import "./Register.css";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";

const Register = ({setLocation, url}) => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [telegramUserName, setTelegramUserName] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [referal, setReferal] = useState("");
    const [checkbox, setCheckbox] = useState(false);
    const error = document.querySelector(".error");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref');

    const register_handler = async (event) => {
        if (email.length <= 0) {
            error.innerText = "Поле почты необходимо заполнить."
            return null
        }

        if (password.length <= 0 || password2.length <= 0) {
            error.innerText = "Поле пароля необходимо заполнить."
            return null
        }

        if (password !== password2) {
            error.innerText = "Пароли не совпадают."
            return null
        }

        if (name.length <= 0) {
            error.innerText = "Поле имени необходимо заполнить."
            return null
        }

        if (telegramUserName.length <= 0) {
            error.innerText = "Поле никнейм телеграм необходимо заполнить."
            return null
        }

        if (checkbox === false) {
            error.innerText = "Необходимо принять условия политики конфиденциальности и лицензионного соглашения Royal Family."
            return null
        }

        if (!email.includes("@")) {
            error.innerText = "Поле почты заполнено неверно."
            return null
        }

        error.innerText = "";
        await axios.post(`${url}/user/register`, {
            email: email,
            name: name,
            telegram_username: telegramUserName,
            password: password,
            password2: password2,
            referal_id: referal
        }, {withCredentials: true}).then((response) => {
            if (response.status === 200) {
                window.location.href = "/"
            }
        }).catch((error_response) => {
            if (error_response.status === 409) {
                error.innerText = "Такой e-mail уже зарегистрирован."
                return null
            } else if (error_response.status === 400) {
                error.innerText = "Пароли не совпадают."
                return null
            } else {
                error.innerText = "Неизвестная ошибка."
                return null
            }
        })
    }

    useEffect(() => {
        setLocation("register");
    }, [setLocation]);

    useEffect(() => {
        if (ref) {
            const ref_element = document.querySelector("#referal");
            if (ref_element) {
                ref_element.value = ref;
                setReferal(ref);
            }
        }
    }, [ref]);

    return (
        <>
            <section className="register">
                <div className="container register-container">
                    <img src="/rf-logo-yellow.png" alt="Royal Family Logo" className="register-logo"/>
                    <div className="register-form">
                        <h2 className="register-title">Регистрация</h2>
                        <span className="register-description">Заполните форму и получите доступ к платформе</span>
                        <input onChange={(event) => setEmail(event.target.value)} type="email"
                               placeholder="Электронная почта" className="register-input"/>
                        <input onChange={(event) => setName(event.target.value)} type="text" placeholder="Никнейм"
                               className="register-input"/>
                        <input onChange={(event) => setTelegramUserName(event.target.value)} type="text"
                               placeholder="Никнейм телеграм" className="register-input"/>
                        <input onChange={(event) => setPassword(event.target.value)} type="password"
                               placeholder="Пароль" className="register-input"/>
                        <input onChange={(event) => setPassword2(event.target.value)} type="password"
                               placeholder="Подтверждение пароля" className="register-input"/>
                        <input onChange={(event) => setReferal(event.target.value)} type="text"
                               placeholder="Код приглашения (если есть)" className="register-input" id="referal"/>
                        <span className="error"></span>
                        <div className="register-checkbox-container">
                            <div className="register-checkbox">
                                <input onChange={(event) => setCheckbox(event.target.checked)} className="checkbox reg" type="checkbox" value="" id="flexCheckChecked"
                                       name="remember"/>
                                <label className="register-check-label" htmlFor="flexCheckChecked">
                                    Я принимаю условия <span
                                    className="yellow-text">политики конфиденциальности</span> и <span
                                    className="yellow-text">лицензионного соглашения</span> Royal Family
                                </label>
                            </div>
                        </div>
                        <button onClick={(event) => register_handler()} type="submit" className="register-button">Зарегистрироваться</button>
                        <Link to="/login" className="register-button transparent">Авторизация</Link>
                    </div>
                    <span className="register-copyright">Royal Family © 2018 - 2024</span>
                </div>
            </section>
        </>
    )
}

export default Register;