import React, {useEffect, useState} from "react";
import "./AdminCheckingAccountNumber.css";
import axios from "axios";

const AdminCheckingAccountNumber = ({setLocationAdmin, url}) => {
    const [accountNumber, setAccountNumber] = useState("")

    const check_account = async () => {
        let hasErrors = false;
        const error_text = document.querySelector(".error");
        const notification_text = document.querySelector(".notification");

        error_text.innerText = "";
        notification_text.innerText = "";

        if (accountNumber.length < 6) {
            error_text.innerText = "Необходимо ввести номер счёта!";
            hasErrors = true;
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/admin/check-account?account_number=${accountNumber}`,
                    {},
                    {withCredentials: true})
                notification_text.innerText = response.data.detail;
            } catch (error_response) {
                error_text.innerText = error_response.response.data.detail;
                console.error('Error checking account', error_response);
            }
        }
    };

    useEffect(() => {
        setLocationAdmin("admin-checking-account-number");
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Проверка номера счёта</h2>
                <div className="admin-checking-account-number-warning">
                    <h3 className="admin-checking-account-number-warning-title">Вводя данные, будь внимателен!</h3>
                    <span className="admin-checking-account-number-warning-info">Не допускай ввод лишних символов, пробелов и прочего!</span>
                </div>
                <div className="admin-checking-account-number-form">
                    <span className="error"></span>
                    <span className="notification" style={{color: "green", fontWeight: "800"}}></span>
                    <label>Номер счёта:</label>
                    <input type="text" required value={accountNumber}
                           onChange={(event) => setAccountNumber(event.target.value)} className="admin-filter-input"/>
                </div>
                <button onClick={() => check_account()}
                        className="admin-checking-account-number-btn admin-filter-apply">Проверить
                </button>
            </section>
        </>
    )
}

export default AdminCheckingAccountNumber;