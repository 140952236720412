import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";

const AdminCategoryAdd = ({setLocationAdmin, url}) => {
    const error_text = document.querySelector(".error");
    const [status, setStatus] = useState(false)
    const [title, setTitle] = useState("")
    const [key, setKey] = useState("")
    const [description, setDescription] = useState("")
    const [icon, setIcon] = useState(null)

    const create_category = async () => {
        let hasErrors = false;

        // Проверка поля cryptowallet
        if (title.length < 1) {
            error_text.innerText = "Поле название не заполнено";
            hasErrors = true;
        } else if (error_text) {
            error_text.innerText = "";
        }

        // Проверка поля cryptocurrency
        if (key < 1) {
            error_text.innerText = "Поле ключ не заполнено";
            hasErrors = true;
        } else if (error_text) {
            error_text.innerText = "";
        }

        if (!hasErrors) {
            try {
                const formData = new FormData();
                formData.append("enabled", status);
                formData.append("name", title);
                formData.append("description", description)
                formData.append("key", key);
                if (icon) {
                    formData.append("icon", icon);
                }
                const response = await axios.post(`${url}/admin/add-category`,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                if (response.status === 200) {
                    window.location.href = "/admin/categories";
                }
            } catch (error_response) {
                console.error('Error creating category', error_response);
                console.log(error_response.response.data)
                error_text.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка!";
            }
        }
    };

    useEffect(() => {
        setLocationAdmin("admin-category-add");
    }, [setLocationAdmin]);

    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Редактирование категории продукта MetricaFX</h2>
                <Link to="/admin/categories" className="experts-product-back-btn">
                    <i className="fa fa-chevron-left"></i>
                    назад
                </Link>
                <div className="admin-edit-block rf-grey-block">
                    <h3 className="admin-edit-title">Форма редактирования</h3>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Статус:</label>
                        <div className="admin-edit-checkbox">
                            <label className="switch">
                                <input type="checkbox" checked={status} onChange={() => setStatus(!status)}/>
                                <span className="slider"></span>
                            </label>
                            <span className="admin-edit-checkbox-text">Вкл.</span>
                        </div>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Название:</label>
                        <input type="text" value={title} onChange={(event) => setTitle(event.target.value)}
                               className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Ключ:</label>
                        <input type="text"
                               value={key}
                               onChange={(event) => setKey(event.target.value)}
                               className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Описание:</label>
                        <textarea value={description} onChange={(event) => setDescription(event.target.value)}
                                  className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Иконка:</label>
                        <input type="file"
                               onChange={(event) => setIcon(event.target.files[0])}
                               className="admin-filter-input"/>
                    </div>
                    <span className="error"></span>
                    <button onClick={() => create_category()}
                            className="admin-checking-account-number-btn admin-filter-apply">Сохранить
                    </button>
                </div>
            </section>
        </>
    )
}

export default AdminCategoryAdd;