import {Link} from "react-router-dom";
import "./Notification.css";

const Notification = () => {
    return (
        <>
            <div className="notification-message">
                <span className="notification-message-title">Внимание!</span>
                <p className="notification-message-text">
                    Чтобы попасть в партнёрский чат, необходимо обратиться в <Link to="https://t.me/RoyalFamily_Support_bot"
                                                                                   className="notification-message-link">техническую
                    поддержку</Link>.
                    <br/>
                    В партнёрский чат можно попасть только при наличии активной подписки на клуб.
                </p>
            </div>
        </>
    )
}

export default Notification