import axios from "axios";

const get_user = async ({ url, setUserData }) => {
    try {
        const response = await axios.get(`${url}/user/me`, { withCredentials: true });

        if (response.status === 200) {
            setUserData(response.data);
            return response.data; // Возвращаем данные пользователя
        } else {
            setUserData(null);
            return null
        }
    } catch (error_response) {
        console.error('Error fetching user data:', error_response);
        setUserData(null); // Устанавливаем userData в null при ошибке
        return null; // Возвращаем null
    }
};

const get_admin = async ({ url, setUserData }) => {
    try {
        const response = await axios.get(`${url}/admin/me`, { withCredentials: true });

        if (response.status === 200) {
            setUserData(response.data);
            return response.data; // Возвращаем данные пользователя
        } else {
            setUserData(null);
            return null;
        }
    } catch (error_response) {
        console.error('Error fetching user data:', error_response);
        setUserData(null); // Устанавливаем userData в null при ошибке
        return null; // Возвращаем null
    }
};


export {get_user, get_admin};