import React, {useEffect} from "react";
import "./HideDrawer.css";
import {Link} from "react-router-dom";

const HideDrawer = ({open_drawer, showDrawer, userData, location}) => {
    const {
        email,
        name,
    } = userData;

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".menu-btn");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
            const menu_img = btn.querySelector(".menu-img")
            menu_img.classList.remove("active");
        });

        event.target.classList.add("active");
        const menu_img = event.target.querySelector(".menu-img")
        menu_img.classList.add("active");
        open_drawer();
    }

    useEffect(() => {
        const drawer = document.querySelector(".hide-drawer")
        if (drawer.classList.contains("open") && !showDrawer) {
            drawer.classList.remove("open")
        } else {
            if (showDrawer) {
                drawer.classList.add("open")
            }
        }
    }, [showDrawer]);

    return (
        <>
            <div className="hide-drawer">
                <header className="hide-drawer-header">
                    <div className="logo">
                        <Link to="#" className="logo-link">
                            <img src="/crown.png" alt="Logo Crown" className="logo-img"/>
                            <img src="/royal-family.png" alt="Logo Royal Family" className="logo-img"/>
                        </Link>
                    </div>
                    <button onClick={open_drawer} className="hide-drawer-btn-close">
                        <img src="/close-btn.svg" alt="Закрыть" className="hide-drawer-btn-close-img"/>
                    </button>
                </header>
                <div className="hide-drawer-column">
                    <div className="hide-drawer-account-info">
                        <button className="nav-link btn">{name[0]}</button>
                        <div className="dropdown-menu-account">
                            <span className="dropdown-menu-name">{name}</span>
                            <span className="dropdown-menu-email">{email}</span>
                        </div>
                    </div>
                    <div className="menu">
                        <ul className="menu-list">
                            <li className="menu-list-item">
                                <Link to="/" className={`menu-btn ${location === "my-account" ? "active" : null}`}
                                      onClick={(event) => active_button(event)}>
                                    <img src={`${location === "my-account" ? "/king-active.svg" : "/king.svg"}`}
                                         alt="Account"
                                         className={`menu-img ${location === "my-account" ? "active" : null}`}></img>
                                    Мой аккаунт
                                </Link>
                            </li>
                            <li className="menu-list-item">
                                <Link to="/wallet" className={`menu-btn ${location === "wallet" ? "active" : null}`}
                                      onClick={(event) => active_button(event)}>
                                    <img src={`${location === "wallet" ? "/wallet-active.svg" : "/wallet.svg"}`}
                                         alt="Wallet"
                                         className={`menu-img ${location === "wallet" ? "active" : null}`}></img>
                                    Мой кошелёк
                                </Link>
                            </li>
                            <li className="menu-list-item">
                                <Link to="/experts"
                                      className={`menu-btn ${location === "experts" || location === "experts-product" || location === "experts-activation" ? "active" : null}`}
                                      onClick={(event) => active_button(event)}>
                                    <img
                                        src={`${location === "experts" || location === "experts-product" || location === "experts-buy" || location === "experts-activation" ? "/experts-active.svg" : "/experts.svg"}`}
                                        alt="Experts"
                                        className={`menu-img ${location === "experts" || location === "experts-product" || location === "experts-activation" ? "active" : null}`}></img>
                                    Торговые системы
                                </Link>
                            </li>
                            <li className="menu-list-item">
                                <Link to="/copying"
                                      className={`menu-btn ${location === "copying" ? "active" : null}`}
                                      onClick={(event) => active_button(event)}>
                                    <img
                                        src={`${location === "copying" ? "/copy-active.svg" : "/copy-grey.svg"}`}
                                        alt="copying"
                                        className={`menu-img ${location === "copying" ? "active" : null}`}></img>
                                    Копирование
                                </Link>
                            </li>
                            <li className="menu-list-item">
                                <Link to="/trade-capital"
                                      className={`menu-btn ${location === "trade-capital" || location === "trade-capital/account" ? "active" : null}`}
                                      onClick={(event) => active_button(event)}>
                                    <img
                                        src={`${location === "trade-capital" || location === "trade-capital/account" ? "/circle.svg" : "/circle-grey.svg"}`}
                                        alt="Trade Capital"
                                        className={`menu-img ${location === "trade-capital" || location === "trade-capital/account" ? "active" : null}`}></img>
                                    Trade Capital
                                </Link>
                            </li>
                            <li className="menu-list-item">
                                <Link to="/metricafx"
                                      className={`menu-btn ${location === "metricafx" ? "active" : null}`}
                                      onClick={(event) => active_button(event)}>
                                    <img
                                        src={`${location === "metricafx" ? "/metricafx-active.svg" : "/metricafx.svg"}`}
                                        alt="MetricaFX"
                                        className={`menu-img ${location === "metricafx" ? "active" : null}`}></img>
                                    MetricaFX
                                </Link>
                            </li>
                            <li className="menu-list-item">
                                <Link to="/partners"
                                      className={`menu-btn ${location === "partners" ? "active" : null}`}
                                      onClick={(event) => active_button(event)}>
                                    <img
                                        src={`${location === "partners" ? "/partners-active.svg" : "/partners.svg"}`}
                                        alt="Partners"
                                        className={`menu-img ${location === "partners" ? "active" : null}`}></img>
                                    Мои партнёры
                                </Link>
                            </li>
                            {/*пригласить друга*/}
                            <li className="menu-list-item">
                                <Link to="/partners/invite"
                                      className={`menu-btn ${location === "partners_invite" ? "active" : null}`}
                                      onClick={(event) => active_button(event)}>
                                    <img
                                        src={`${location === "partners_invite" ? "/user-plus-menu-active.svg" : "/user-plus-menu.svg"}`}
                                        alt="Partners"
                                        className={`menu-img ${location === "partners_invite" ? "active" : null}`}/>
                                    Пригласить друга
                                </Link>
                            </li>
                            {/*поддержка*/}
                            <li className="menu-list-item">
                                <Link to="https://t.me/RoyalFamily_Support_bot"
                                      className="menu-btn">
                                    <i className="fa fa-headset"/>
                                    Поддержка
                                </Link>
                            </li>
                            {/*выход*/}
                            <li className="menu-list-item">
                                <Link to="#"
                                      className="menu-btn">
                                    <img
                                        src="/logout.svg"
                                        alt="Partners"
                                        className="menu-img logout-img"/>
                                    Выход
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HideDrawer