import React, {useEffect, useState} from "react";
import "./SubscriptionRenewalScreen.css";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

const SubscriptionRenewalScreen = ({url}) => {
    const [checkbox, setCheckbox] = useState(false);
    const [period, setPeriod] = useState(6);
    const [price, setPrice] = useState(null);
    const [tariffs, setTariffs] = useState(null);
    const { account_number} = useParams();

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".subscription-renewal-tariffs-item");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
        });

        event.target.classList.add("active");
    }

    const renew_subscription = async () => {
        const error = document.querySelector(".method-text-error.error");
        const error_checkbox = document.querySelector(".method-text-error.checkbox-error");

        let hasErrors = false;

        // Проверка чекбокса
        if (!price) {
            error.innerText = "Необходимо выбрать тариф.";
            hasErrors = true;
        } else {
            error.innerText = "";
        }

        if (!checkbox) {
            error_checkbox.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_checkbox.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/subscribe/renew_subscription?account_number=${account_number}&months=${period}`,
                    {},
                    {withCredentials: true})
                setTariffs(response.data);
                window.location = "/experts";
            } catch (error_response) {
                console.error('Error fetching tariffs data', error_response);
                console.log(error_response);
                error_checkbox.innerText = error_response.response.data.detail ? error_response.response.data.detail : "Неизвестная ошибка.";
            }
        }
    };

    useEffect(() => {
        const getTariffsData = async () => {
            try {
                const response = await axios.get(`${url}/subscribe/get-subscribes`, {withCredentials: true})
                setTariffs(response.data.sort((a, b) => a.months - b.months));
            } catch (error_response) {
                console.error('Error fetching tariffs data', error_response);
            }
        }
        getTariffsData();
    }, [url]);

    return (
        <>
            <div className="subscription-renewal-row">
                <div className="subscription-renewal-tariffs">
                    <h4 className="subscription-renewal-tariffs-title">Тарифы</h4>
                    {tariffs && (
                        <ul className="subscription-renewal-tariffs-list">
                            {tariffs.map((tariff) => (
                                <li key={tariff.id} className="subscription-renewal-tariffs-item" onClick={(event) => {
                                    active_button(event);
                                    setPeriod(tariff.months);
                                    setPrice(!tariff.sale_enabled ? tariff.price : tariff.sale_price);
                                }}>
                                    {!tariff.sale_enabled ? (
                                        <>
                                            <span
                                                className="subscription-renewal-tariffs-item-text">{+tariff.months > 1000 ? `Безлимитная подписка` : `Подписка на ${tariff.months} мес.`}</span>
                                            <span
                                                className="subscription-renewal-tariffs-item-text">{tariff.price / 100} $</span>
                                        </>
                                    ) : (
                                        <>
                                            <span
                                                className="subscription-renewal-tariffs-item-text">{+tariff.months > 1000 ? `Безлимитная подписка` : `Подписка на ${tariff.months} мес.`}</span>
                                            <span
                                                className="subscription-renewal-tariffs-item-text crossed">{tariff.price / 100} $</span>
                                            <span
                                                className="subscription-renewal-tariffs-item-text">{tariff.sale_price / 100} $</span>
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                    <span className="method-text-error error"></span>
                    <div className="subscription-renewal-price">
                        {price && (
                            <>
                                <h4 className="subscription-renewal-price-title">К оплате</h4>
                                <span className="subscription-renewal-price-num">{price / 100} $
                    </span>
                            </>
                        )}
                    </div>
                    <div className="checkbox-block">
                        <div className="checkbox-block-row">
                            <input type="checkbox" className="checkbox" checked={checkbox}
                                   onChange={(event) => setCheckbox(event.target.checked)}/>
                            <span className="checkbox-text">Я принимаю <Link to="#" className="invite-friend-link">условия продления</Link> подписки.</span>
                        </div>
                        <span className="method-text-error checkbox-error"></span>
                    </div>
                    <button className="method-of-replenishment-btn" onClick={renew_subscription}>{+period > 1000 ? `Перейти на безлимитную подписку` : `Продлить
                        подписку на ${period} мес.`}
                    </button>
                </div>
                <div className="subscription-renewal-info">
                    <h4 className="subscription-renewal-tariffs-title">Будет продлено</h4>
                    <ul className="subscription-renewal-info-list">
                        <li className="subscription-renewal-info-item">
                            <div className="subscription-renewal-info-img">
                                <i className="fa fa-robot"></i>
                            </div>
                            <div className="subscription-renewal-info-item-block">
                                <h5 className="subscription-renewal-info-item-title">Торговый советник</h5>
                                <span className="subscription-renewal-info-item-text">Любой торговый советник из доступной линейки продуктов</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SubscriptionRenewalScreen