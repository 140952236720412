import "./Login.css";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";

const Login = ({setLocation, url}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const error = document.querySelector(".error");

    const login_handler = async (event) => {
        event.preventDefault();

        if (email.length <= 0) {
            error.innerText = "Поле почты необходимо заполнить."
            return null
        }

        if (password.length <= 0) {
            error.innerText = "Поле пароля необходимо заполнить."
            return null
        }

        if (!email.includes("@")) {
            error.innerText = "Поле почты заполнено неверно."
            return null
        }
        try {
            const response = await axios.post(`${url}/user/login`, {
                email: email,
                password: password,
            }, {withCredentials: true})
            if (response.status === 200) {
                window.location.href = "/"
            }
        } catch (error_response) {
            if (error_response.status === 401) {
                error.innerText = "Почта или пароль введены неверно."
                return null
            }
            console.error('Error fetching accounts data', error_response);
        }
    }

    useEffect(() => {
        setLocation("login");
    }, [setLocation]);

    return (
        <>
            <section className="login">
                <div className="container login-container">
                    <img src="/rf-logo-yellow.png" alt="Royal Family Logo" className="login-logo"/>
                    <form onSubmit={event => login_handler(event)} className="login-form">
                        <h2 className="login-title">Вход</h2>
                        <span className="login-description">Введите свои данные чтобы продолжить</span>
                        <input onChange={(event) => setEmail(event.target.value)} type="email"
                               placeholder="Электронная почта" className="login-input"/>
                        <input onChange={(event) => setPassword(event.target.value)} type="password"
                               placeholder="Пароль" className="login-input"/>
                        <span className="error"></span>
                        <div className="login-checkbox-container">
                            <div className="login-checkbox">
                                <input className="checkbox" type="checkbox" value="" id="flexCheckChecked"
                                       name="remember"/>
                                <label className="login-check-label" htmlFor="flexCheckChecked">
                                    Запомнить меня
                                </label>
                            </div>
                            <Link className="login-remember-me" to="/forgot-password">
                                Восстановить пароль
                            </Link>
                        </div>
                        <button type="submit" className="login-button">Войти</button>
                        <Link to="/register" className="login-button transparent">Регистрация</Link>
                    </form>
                    <span className="login-copyright">Royal Family © 2018 - 2024</span>
                </div>
            </section>
        </>
    )
}

export default Login;