import React, {useEffect, useState} from "react";
import "./DrawerAdmin.css";
import {Link} from "react-router-dom";
import axios from "axios";

const DrawerAdmin = ({url, locationAdmin, permissions}) => {



    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".menu-btn");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
            const menu_img = btn.querySelector(".menu-img")
            if (menu_img) {
                menu_img.classList.remove("active");
            }
        });

        event.target.classList.add("active");
        const menu_img = event.target.querySelector(".menu-img")
        menu_img.classList.add("active");
    }

    return (
        <>
            <section className="drawer-admin">
                <div className="container">
                    <header className="hide-drawer-header">
                        <div className="logo">
                            <img src="/rf-logo-yellow.png" alt="" className="logo-img"/>
                        </div>
                    </header>
                    <div className="drawer-column">
                        <div className="menu">
                            {permissions && (
                                <>
                                    <ul className="menu-list">
                                        {permissions.admin_main_page && (
                                            <li className="menu-list-item">
                                                {/*<Link to="/" className={`menu-btn ${location === "my-account" ? "active" : null}`}*/}
                                                {/*      onClick={(event) => active_button(event)}>*/}
                                                <Link to="/admin"
                                                      className={`menu-btn ${locationAdmin === "admin-base" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/chart.svg"
                                                         alt="Главная"
                                                         className="menu-img active"></img>
                                                    Главная
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_users_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/users"
                                                      className={`menu-btn ${locationAdmin === "admin-users" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/people.svg"
                                                         alt="Пользователи"
                                                         className="menu-img"></img>
                                                    Пользователи
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_tc_users_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/usersTS"
                                                      className={`menu-btn ${locationAdmin === "admin-usersts" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/people.svg"
                                                         alt="Пользователи ТС"
                                                         className="menu-img"></img>
                                                    Пользователи ТС
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_trading_accounts_page && (
                                            <li className="menu-list-item">
                                                {/*<Link to="/" className={`menu-btn ${location === "my-account" ? "active" : null}`}*/}
                                                {/*      onClick={(event) => active_button(event)}>*/}
                                                <Link to="/admin/trading-accounts"
                                                      className={`menu-btn ${locationAdmin === "admin-trading-accounts" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/robot.svg"
                                                         alt="Торговые счета"
                                                         className="menu-img"></img>
                                                    Торговые счета
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                    <h3 className="admin-list-title">Финансы</h3>
                                    <ul className="menu-list">
                                        {permissions.admin_transactions_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/transactions"
                                                      className={`menu-btn ${locationAdmin === "admin-transactions" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/coins.svg"
                                                         alt="Транзакции"
                                                         className="menu-img"></img>
                                                    Транзакции
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_withdraw_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/withdrawals"
                                                      className={`menu-btn ${locationAdmin === "admin-withdrawals" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/coins.svg"
                                                         alt="Выводы"
                                                         className="menu-img"></img>
                                                    Выводы
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                    <h3 className="admin-list-title">Продукты</h3>
                                    <ul className="menu-list">
                                        {permissions.admin_products_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/products"
                                                      className={`menu-btn ${locationAdmin === "admin-products" ||
                                                      locationAdmin === "admin-product-edit" ||
                                                      locationAdmin === "admin-product-info" ||
                                                      locationAdmin === "admin-product-add" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/box.svg"
                                                         alt="Продукты"
                                                         className="menu-img"></img>
                                                    Продукты
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_categories_page && (
                                        <li className="menu-list-item">
                                            <Link to="/admin/categories"
                                                  className={`menu-btn ${locationAdmin === "admin-categories" || 
                                                  locationAdmin === "admin-category-edit" || 
                                                  locationAdmin === "admin-category-add" ? "active" : null}`}
                                                  onClick={(event) => active_button(event)}>
                                                <img src="/admin%20icons/categories.svg"
                                                     alt="Категории"
                                                     className="menu-img"></img>
                                                Категории
                                            </Link>
                                        </li>
                                        )}
                                        {permissions.admin_tariffs_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/tariffs"
                                                      className={`menu-btn ${locationAdmin === "admin-tariffs" ||
                                                      locationAdmin === "admin-tariff-info" ||
                                                      locationAdmin === "admin-tariff-edit" ||
                                                      locationAdmin === "admin-tariff-add" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/dollar.svg"
                                                         alt="Тарифы"
                                                         className="menu-img"></img>
                                                    Тарифы
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_copy_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/copying"
                                                      className={`menu-btn ${locationAdmin === "admin-copying" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/copy-admin.svg"
                                                         alt="Копирование"
                                                         className="menu-img"></img>
                                                    Копирование
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                    <h3 className="admin-list-title">Утилиты</h3>
                                    <ul className="menu-list">
                                        {permissions.admin_check_account_number_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/checking-account-number"
                                                      className={`menu-btn ${locationAdmin === "admin-checking-account-number" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/done.svg"
                                                         alt="Проверка номера счёта"
                                                         className="menu-img"></img>
                                                    Проверка номера счёта
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_email_replacement_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/replacement-mail"
                                                      className={`menu-btn ${locationAdmin === "admin-replacement-mail" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/email.svg"
                                                         alt="Замена почты"
                                                         className="menu-img"></img>
                                                    Замена почты
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_tc_email_replacement_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/replacement-mailts"
                                                      className={`menu-btn ${locationAdmin === "admin-replacement-mailts" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/email.svg"
                                                         alt="Замена почты ТС"
                                                         className="menu-img"></img>
                                                    Замена почты ТС
                                                </Link>
                                            </li>
                                        )}
                                        {permissions.admin_account_number_replacement_page && (
                                            <li className="menu-list-item">
                                                <Link to="/admin/replacing-account-number"
                                                      className={`menu-btn ${locationAdmin === "admin-replacing-account-number" ? "active" : null}`}
                                                      onClick={(event) => active_button(event)}>
                                                    <img src="/admin%20icons/arrows.svg"
                                                         alt="Замена номера счёта"
                                                         className="menu-img"></img>
                                                    Замена номера счёта
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                    <h3 className="admin-list-title">Выход</h3>
                                    <ul className="menu-list">
                                        <li className="menu-list-item">
                                            <Link
                                                to="#"
                                                className="menu-btn"
                                                onClick={(event) => active_button(event)}>
                                                <img src="/admin%20icons/logout.svg"
                                                     alt="Проверка номера счёта"
                                                     className="menu-img"></img>
                                                Выйти
                                            </Link>
                                        </li>
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DrawerAdmin;