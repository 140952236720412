import React, {useEffect, useState} from "react";
import "./AdminCopying.css";
import axios from "axios";
import {date_formatter} from "../../../components/formatter";

const AdminCopying = ({setLocationAdmin, url}) => {
    const [type, setType] = useState("pending");
    const [copyingData, setCopyingData] = useState(null);
    const [filterBy, setFilterBy] = useState("");
    const error_text = document.querySelector(".error");
    const [maxPerPage, setMaxPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const get_copying = async () => {
            try {
                const response = await axios.get(`${url}/admin/get-copying?offset=${(currentPage - 1) * 10}&limit=${maxPerPage}&filter_by=${filterBy ? filterBy : ""}`,
                    {withCredentials: true})
                setCopyingData(response.data);
            } catch (error_response) {
                error_text.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка!";
                console.error('Error fetching transactions', error_response);
            }
        }

    const reset_search = async () => {
        const admin_filter_input = document.querySelector(".admin-filter-input")
        admin_filter_input.value = "";
        setFilterBy("");
    }

    const accept_copying = async (copying_id) => {
        try {
            const response = await axios.post(`${url}/admin/accept-copying?copying_id=${copying_id}`,
                {},
                {withCredentials: true})
            if (response.status === 200) {
                await get_copying();
            }
        } catch (error_response) {
            console.error('Error acception of the copying', error_response);
        }
    }

    const cancel_copying = async (copying_id) => {
        try {
            const response = await axios.post(`${url}/admin/cancel-copying?copying_id=${copying_id}`,
                {},
                {withCredentials: true})
            if (response.status === 200) {
                await get_copying();
            }
        } catch (error_response) {
            console.error('Error cancellation of the copying', error_response);
        }
    }

    useEffect(() => {
        get_copying();
    }, [url, maxPerPage, currentPage, filterBy]);

    const open_list = (list) => {
        const section = document.getElementById(`withdrawal-1`)
        const section2 = document.getElementById(`withdrawal-2`)
        if (list === "pending") {
            if (!section.classList.contains("active")) {
                section.classList.add("active")
                if (section2.classList.contains("active")) {
                    section2.classList.remove("active")
                }
            }
        } else if (list === "all") {
            if (!section2.classList.contains("active")) {
                section2.classList.add("active")
                if (section.classList.contains("active")) {
                    section.classList.remove("active")
                }
            }
        }
    }

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    useEffect(() => {
        setCurrentPage(1);
    }, [filterBy, ]);

    useEffect(() => {
        setLocationAdmin("admin-copying")
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Копирование</h2>
                <div className="admin-filter rf-grey-block">
                    <div className="admin-filter-row">
                        <h3 className="admin-base-row-title">Фильтр</h3>
                        <span className="error"></span>
                        <div className="admin-filter-btns">
                            <button onClick={() => reset_search()} className="admin-filter-reset">Сбросить</button>
                        </div>
                    </div>
                    <input onChange={(event) => setFilterBy(event.target.value)} type="text" placeholder="Что ищем?" className="admin-filter-input"/>
                </div>
                <div className="admin-filter rf-grey-block">
                    <span style={{display: "flex", justifyContent: "space-between"}}>
                        <div></div>
                    <div className="method-input-block-base" style={{width: "100px"}}>
                        <div className="method-input-block" onClick={open_options}>
                        <span
                            className="method-input-placeholder">{maxPerPage}</span>
                            <i className="fa fa-chevron-down"></i>
                        </div>
                        <div className="method-options-block">
                            <ul className="method-options-list">
                                <li className="method-options-item"
                                    onClick={() => {
                                        setMaxPerPage(10);
                                        open_options();
                                    }}>10
                                </li>
                                <li className="method-options-item" onClick={() => {
                                    setMaxPerPage(25);
                                    open_options();
                                }}>25
                                </li>
                                <li className="method-options-item" onClick={() => {
                                    setMaxPerPage(50);
                                    open_options();
                                }}>50
                                </li>
                                <li className="method-options-item" onClick={() => {
                                    setMaxPerPage(100);
                                    open_options();
                                }}>100
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
                    <div className="admin-withdrawals-type-row">
                        <button id="withdrawal-1" className="admin-withdrawals-type active"
                                onClick={() => {
                                    setType("pending");
                                    open_list("pending")
                                }}>
                            Ожидают подключения
                        </button>
                        <button id="withdrawal-2" className="admin-withdrawals-type" onClick={() => {
                            setType("all");
                            open_list("all")
                        }}>
                            Исполненные
                        </button>
                    </div>
                    {type === "pending" ? (
                        <>
                            {copyingData && (
                                copyingData.pending.length > 0 ? (
                                    <>
                                        <table className="admin-users-table">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Пользователь</th>
                                                <th>Статус</th>
                                                <th>Номер счета</th>
                                                <th>Дата</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {copyingData.pending.map((item) => (
                                                <tr key={item.id}>
                                                    <th>{item.id}</th>
                                                    <th>
                                                        <div className="admin-users-table-user-row">
                                                            <div className="admin-users-table-user-info">
                                                            <span
                                                                className="admin-users-table-user-username">{item.user.name}</span>
                                                                <span
                                                                    className="admin-users-table-user-email">{item.user.email}</span>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className="admin-users-table-user-tg">{item.status === "pending" ? "В обработке" : item.status === "completed" ? "Завершена" : item.status === "canceled" ? "Отменена" : item.status}</span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className="admin-users-table-user-email">{item.account_number}</span>
                                                    </th>
                                                    <th>
                                                <span
                                                    className="admin-users-table-user-email">{date_formatter(item.created_at)}</span>
                                                    </th>
                                                    <th className="eye-btn">
                                                        <div className="admin-users-table-btns withdrawals-btns">
                                                            <button onClick={() => accept_copying(item.id)}
                                                                    className="admin-users-table-edit withdrawals-btn">
                                                                <img src="/admin%20icons/done-green.svg" alt="Done"
                                                                     className="admin-users-table-edit-img"/>
                                                            </button>
                                                            <button onClick={() => cancel_copying(item.id)}
                                                                    className="admin-users-table-edit withdrawals-btn">
                                                                <img src="/admin%20icons/cross-red.svg" alt="Delete"
                                                                     className="admin-users-table-edit-img"/>
                                                            </button>
                                                        </div>
                                                    </th>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        {copyingData && (
                                            <div className="pagination">
                                            <span className="small-text">
                                                Показано с <b>1</b> по <b>10</b> из <b>{copyingData.pending_count}</b> результатов
                                            </span>
                                                <ul className="pagination-pages">
                                                    {copyingData.pending_count > maxPerPage && (() => {
                                                        const totalPages = Math.ceil(copyingData.pending_count / maxPerPage);
                                                        const maxVisiblePages = 5; // количество видимых страниц
                                                        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                                                        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

                                                        const shouldShowLeftDots = startPage > 1;
                                                        const shouldShowRightDots = endPage < totalPages;

                                                        const paginationItems = [];

                                                        // Первая страница
                                                        if (shouldShowLeftDots) {
                                                            paginationItems.push(
                                                                <button
                                                                    key="first"
                                                                    className="page-number"
                                                                    onClick={() => setCurrentPage(1)}
                                                                >
                                                                    1
                                                                </button>
                                                            );
                                                            paginationItems.push(<span key="left-dots"
                                                                                       className="dots">...</span>);
                                                        }

                                                        // Основные страницы
                                                        for (let page = startPage; page <= endPage; page++) {
                                                            paginationItems.push(
                                                                <button
                                                                    key={page}
                                                                    className={`page-number ${page === currentPage ? "active" : ""}`}
                                                                    onClick={() => setCurrentPage(page)}
                                                                >
                                                                    {page}
                                                                </button>
                                                            );
                                                        }

                                                        // Последняя страница
                                                        if (shouldShowRightDots) {
                                                            paginationItems.push(<span key="right-dots"
                                                                                       className="dots">...</span>);
                                                            paginationItems.push(
                                                                <button
                                                                    key="last"
                                                                    className="page-number"
                                                                    onClick={() => setCurrentPage(totalPages)}
                                                                >
                                                                    {totalPages}
                                                                </button>
                                                            );
                                                        }

                                                        return paginationItems;
                                                    })()}
                                                </ul>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <span>Список пуст...</span>
                                ))}
                        </>
                    ) : (
                        <>
                            {copyingData && (
                                copyingData.all.length > 0 ? (
                                    <>
                                        <table className="admin-users-table">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Пользователь</th>
                                                <th>Статус</th>
                                                <th>Номер счета</th>
                                                <th>Дата</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {copyingData.all.map((item) => (
                                                <tr key={item.id}>
                                                    <th>{item.id}</th>
                                                    <th>
                                                        <div className="admin-users-table-user-row">
                                                            <div className="admin-users-table-user-info">
                                                            <span
                                                                className="admin-users-table-user-username">{item.user.name}</span>
                                                                <span
                                                                    className="admin-users-table-user-email">{item.user.email}</span>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className="admin-users-table-user-tg">{item.status === "pending" ? "В обработке" : item.status === "completed" ? "Завершена" : item.status === "canceled" ? "Отменена" : item.status}</span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className="admin-users-table-user-email">{item.account_number}</span>
                                                    </th>
                                                    <th>
                                                <span
                                                    className="admin-users-table-user-email">{date_formatter(item.created_at)}</span>
                                                    </th>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        {copyingData && (
                                            <div className="pagination">
                                            <span className="small-text">
                                                Показано с <b>1</b> по <b>10</b> из <b>{copyingData.all_count}</b> результатов
                                            </span>
                                                <ul className="pagination-pages">
                                                    {copyingData.all_count > maxPerPage && (() => {
                                                        const totalPages = Math.ceil(copyingData.all_count / maxPerPage);
                                                        const maxVisiblePages = 5; // количество видимых страниц
                                                        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                                                        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

                                                        const shouldShowLeftDots = startPage > 1;
                                                        const shouldShowRightDots = endPage < totalPages;

                                                        const paginationItems = [];

                                                        // Первая страница
                                                        if (shouldShowLeftDots) {
                                                            paginationItems.push(
                                                                <button
                                                                    key="first"
                                                                    className="page-number"
                                                                    onClick={() => setCurrentPage(1)}
                                                                >
                                                                    1
                                                                </button>
                                                            );
                                                            paginationItems.push(<span key="left-dots"
                                                                                       className="dots">...</span>);
                                                        }

                                                        // Основные страницы
                                                        for (let page = startPage; page <= endPage; page++) {
                                                            paginationItems.push(
                                                                <button
                                                                    key={page}
                                                                    className={`page-number ${page === currentPage ? "active" : ""}`}
                                                                    onClick={() => setCurrentPage(page)}
                                                                >
                                                                    {page}
                                                                </button>
                                                            );
                                                        }

                                                        // Последняя страница
                                                        if (shouldShowRightDots) {
                                                            paginationItems.push(<span key="right-dots"
                                                                                       className="dots">...</span>);
                                                            paginationItems.push(
                                                                <button
                                                                    key="last"
                                                                    className="page-number"
                                                                    onClick={() => setCurrentPage(totalPages)}
                                                                >
                                                                    {totalPages}
                                                                </button>
                                                            );
                                                        }

                                                        return paginationItems;
                                                    })()}
                                                </ul>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <span>Список пуст...</span>
                                ))}
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default AdminCopying;