import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import './index.css';
import Header from "./components/Header/header";
import MyAccount from "./pages/MyAccount/MyAccount";
import Drawer from "./components/Drawer/drawer";
import Wallet from "./pages/Wallet/Wallet";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Experts from "./pages/Experts/Experts";
import MetricaFX from "./pages/MetricaFX/MetricaFX";
import Partners from "./pages/Partners/Partners";
import PartnersInvite from "./pages/Partners/PartnersInvite/PartnersInvite";
import ExpertsProduct from "./pages/Experts/ExpertsProduct/ExpertsProduct";
import TradeCapital from "./pages/TradeCapital/TradeCapital";
import TradeCapitalAccount from "./pages/TradeCapital/TradeCapitalAccount/TradeCapitalAccount";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import {get_admin, get_user} from "./components/auth";
import axios from "axios";
import ExpertBuy from "./pages/Experts/ExpertBuy/ExpertBuy";
import ExpertsActivation from "./pages/Experts/ExpertsActivation/ExpertsActivation";
import Admin from "./pages/Admin/AdminBase/Admin";
import DrawerAdmin from "./components/DrawerAdmin/DrawerAdmin";
import AdminUsers from "./pages/Admin/AdminUsers/AdminUsers";
import AdminUsersTS from "./pages/Admin/AdminUsersTS/AdminUsersTS";
import AdminSubscriptions from "./pages/Admin/AdminSubscriptions/AdminSubscriptions";
import AdminTradingAccounts from "./pages/Admin/AdminTradingAccounts/AdminTradingAccounts";
import AdminTransactions from "./pages/Admin/AdminTransactions/AdminTransactions";
import AdminWithdrawals from "./pages/Admin/AdminWithdrawals/AdminWithdrawals";
import AdminProducts from "./pages/Admin/AdminProducts/AdminProducts";
import AdminProductInfo from "./pages/Admin/AdminProducts/AdminProductInfo/AdminProductInfo";
import AdminCategories from "./pages/Admin/AdminCategories/AdminCategories";
import AdminTariffs from "./pages/Admin/AdminTariffs/AdminTariffs";
import AdminCheckingAccountNumber from "./pages/Admin/AdminCheckingAccountNumber/AdminCheckingAccountNumber";
import AdminReplacementMail from "./pages/Admin/AdminReplacementMail/AdminReplacementMail";
import AdminReplacementMailTS from "./pages/Admin/AdminReplacementMailTS/AdminReplacementMailTS";
import AdminReplacingAccountNumber from "./pages/Admin/AdminReplacingAccountNumber/AdminReplacingAccountNumber";
import AdminTariffInfo from "./pages/Admin/AdminTariffs/AdminTariffInfo/AdminTariffInfo";
import AdminProductEdit from "./pages/Admin/AdminProducts/AdminProductEdit/AdminProductEdit";
import AdminProductFileEdit
    from "./pages/Admin/AdminProducts/AdminProductInfo/AdminProductFileEdit/AdminProductFileEdit";
import AdminTariffEdit from "./pages/Admin/AdminTariffs/AdminTariffEdit/AdminTariffEdit";
import AdminProductAdd from "./pages/Admin/AdminProducts/AdminProductAdd/AdminProductAdd";
import AdminTariffAdd from "./pages/Admin/AdminTariffs/AdminTariffAdd/AdminTariffAdd";
import Copying from "./pages/Copying/Copying";
import AdminCopying from "./pages/Admin/AdminCopying/AdminCopying";
import AdminCategoriesEdit from "./pages/Admin/AdminCategories/AdminCategoryEdit/AdminCategoryEdit";
import AdminCategoryAdd from "./pages/Admin/AdminCategories/AdminCategoryAdd/AdminCategoryAdd";

const Layout = ({location, url}) => {
    const [userData, setUserData] = useState(null);
    const [tCAccounts, setTCAccounts] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            const fetchedUserData = await get_user({url, setUserData});

            if (!fetchedUserData && window.location.pathname !== "/login" && window.location.pathname !== "/register") {
                window.location.href = "/login";
            } else if (fetchedUserData && window.location.pathname === "/login") {
                window.location.href = "/";
            }
        };
        checkAuth()
    }, [url, location]);

    useEffect(() => {
        const get_tc_accounts = async () => {
            try {
                if (userData) {
                    const response = await axios.get(`${url}/account/get-tc-accounts`, {withCredentials: true});
                    setTCAccounts(response.data);
                }
            } catch (error_response) {
                console.error('Error fetching TC data', error_response);
            }
        }
        get_tc_accounts();
    }, [url, userData]);

    if (userData === null && (window.location.pathname !== "/login" && window.location.pathname !== "/register" && !window.location.pathname.includes("/admin"))) {
        return (
            <>
                <section className="loading">
                    <div className="container loading-container">
                        <span className="loading-text">Loading...</span>
                    </div>
                </section>
            </>
        )
    } else {
        return (
            <>
                {location !== "error-page" && location !== "register" && location !== "login" && location !== "" ? (
                    <>
                        <Header location={location} url={url} userData={userData}/>
                        <div className="layout">
                            <div className="layout-container">
                                <Drawer location={location} tCAccounts={tCAccounts} url={url}/>
                                <main className="main-content">
                                    {userData ? (
                                        <Outlet context={{userData, tCAccounts, setUserData}}/>
                                    ) : "Загрузка..."}
                                </main>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <main className="main-content">
                            <Outlet/>
                        </main>
                    </>
                )}
            </>
        )
    }
}

const AdminLayout = ({url, locationAdmin}) => {
    const [userData, setUserData] = useState(null);
    const [permissions, setPermissions] = useState(null);

    useEffect(() => {
        const checkAdminAuth = async () => {
            const fetchedUserData = await get_admin({url, setUserData});

            if (!fetchedUserData && window.location.pathname !== "/login" && window.location.pathname !== "/register") {
                window.location.href = "/login";
            } else if (fetchedUserData && window.location.pathname === "/login") {
                window.location.href = "/";
            }
        };
        checkAdminAuth()
    }, [url]);

    useEffect(() => {
        const get_permissions = async () => {
            try {
                const response = await axios.get(`${url}/admin/get-permissions`, {withCredentials: true})
                setPermissions(response.data);
            } catch (error_response) {
                console.error('Error fetching permissions data', error_response);
            }
        }
        get_permissions()
    }, [url]);

    if (userData === null && (window.location.pathname !== "/login" && window.location.pathname !== "/register")) {
        return (
            <>
                <section className="loading">
                    <div className="container loading-container">
                        <span className="loading-text">Loading...</span>
                    </div>
                </section>
            </>
        )
    }

    return (
        <>
            {locationAdmin.includes("admin-") ? (
                <>
                    {userData != null && permissions !== null ? (
                        <div className="layout admin-layout">
                            <div className="layout-container">
                                <DrawerAdmin url={url} locationAdmin={locationAdmin} permissions={permissions}/>
                                <main className="main-content">
                                    <Outlet context={{userData}}/>
                                </main>
                            </div>
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <main className="main-content">
                        <Outlet/>
                    </main>
                </>
            )}
        </>
    )
}

const App = () => {
    const [location, setLocation] = useState("");
    const [locationAdmin, setLocationAdmin] = useState("");
    const url = "https://v167547.hosted-by-vdsina.com/api";
    // const url = "http://localhost:8000/api";
    return (
        <BrowserRouter>
            <Routes>
                {!window.location.pathname.includes("/admin") ? (
                    <Route path="/" element={<Layout location={location} url={url} locationAdmin={locationAdmin}/>}>
                        <Route index element={
                            <MyAccount setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="register" element={
                            <Register setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="login" element={
                            <Login setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="wallet" element={
                            <Wallet setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="trade-capital" element={
                            <TradeCapital setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="trade-capital/account/:id" element={
                            <TradeCapitalAccount setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="experts" element={
                            <Experts setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="experts/product/:account_number" element={
                            <ExpertsProduct setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="experts/activation/:account_id" element={
                            <ExpertsActivation setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="experts/buy" element={
                            <ExpertBuy setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="copying" element={
                            <Copying setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="metricafx" element={
                            <MetricaFX setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="partners" element={
                            <Partners setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                        <Route path="partners/invite" element={
                            <PartnersInvite setLocation={setLocation} url={url}/>
                        }>
                        </Route>
                    </Route>
                ) : (
                    <Route path="/"
                           element={<AdminLayout location={location} url={url} locationAdmin={locationAdmin}/>}>
                        {/*admin*/}

                        <Route path="admin" element={
                            <Admin setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/users" element={
                            <AdminUsers setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/usersTS" element={
                            <AdminUsersTS setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/usersTS" element={
                            <AdminUsersTS setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/subscriptions" element={
                            <AdminSubscriptions setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/trading-accounts" element={
                            <AdminTradingAccounts setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/transactions" element={
                            <AdminTransactions setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/withdrawals" element={
                            <AdminWithdrawals setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/products" element={
                            <AdminProducts setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/product/:id" element={
                            <AdminProductInfo setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/product/:id/file/:id/edit" element={
                            <AdminProductFileEdit setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/categories" element={
                            <AdminCategories setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/tariffs" element={
                            <AdminTariffs setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/tariff/:id" element={
                            <AdminTariffInfo setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/copying" element={
                            <AdminCopying setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/checking-account-number" element={
                            <AdminCheckingAccountNumber setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/replacement-mail" element={
                            <AdminReplacementMail setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/replacement-mailts" element={
                            <AdminReplacementMailTS setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/replacing-account-number" element={
                            <AdminReplacingAccountNumber setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>


                        {/*edit*/}
                        <Route path="admin/product/edit/:id" element={
                            <AdminProductEdit setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/category/edit/:id" element={
                            <AdminCategoriesEdit setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/tariff/edit/:id" element={
                            <AdminTariffEdit setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>

                        {/*add*/}
                        <Route path="admin/product/add" element={
                            <AdminProductAdd setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        {/*<Route path="admin/product/:id/file/add" element={*/}
                        {/*    <AdminProductFileAdd setLocationAdmin={setLocationAdmin} url={url}/>*/}
                        {/*}>*/}
                        {/*</Route>*/}
                        <Route path="admin/category/add" element={
                            <AdminCategoryAdd setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        <Route path="admin/tariff/add" element={
                            <AdminTariffAdd setLocationAdmin={setLocationAdmin} url={url}/>
                        }>
                        </Route>
                        {/*<Route path="admin/tariff/:id/file/add" element={*/}
                        {/*    <AdminTariffFileAdd setLocationAdmin={setLocationAdmin} url={url}/>*/}
                        {/*}>*/}
                        {/*</Route>*/}
                        {/**/}
                        <Route path="*" element={
                            <ErrorPage setLocation={setLocation}/>
                        }>
                        </Route>
                    </Route>
                )}
            </Routes>
        </BrowserRouter>
    )
}

ReactDOM.createRoot(document.getElementById('main')).render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

