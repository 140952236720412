import React, {useEffect} from "react";
import "./AdminTariffInfo.css";
import {Link, useParams} from "react-router-dom";

const AdminTariffInfo = ({setLocationAdmin, url}) => {
    const {id} = useParams();

    useEffect(() => {
        setLocationAdmin("admin-tariff-info")
    }, [setLocationAdmin])

    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Набор тарифа</h2>
                <Link to="/admin/tariffs" className="experts-product-back-btn">
                    <i className="fa fa-chevron-left"></i>
                    назад
                </Link>
                <div className="admin-filter rf-grey-block">
                    <div className="admin-categories-title-row">
                        <h3 className="admin-base-row-title">Список продуктов</h3>
                        <Link to="/admin/tariff/11/file/add" className="admin-categories-btn">
                            Добавить
                        </Link>
                    </div>
                    <table className="admin-users-table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Продукт</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th className="th-btns">11</th>
                            <th>Торговый советник</th>
                            <th className="th-btns eye-btn">
                                <div className="admin-users-table-btns">
                                    <button className="admin-users-table-edit">
                                        <img src="/admin%20icons/delete.svg" alt="Delete"
                                             className="admin-users-table-edit-img"/>
                                    </button>
                                </div>
                            </th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}
export default AdminTariffInfo;
