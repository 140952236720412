import React, {useEffect, useState} from "react";
import "./operationhistory-wallet.css";
import axios from "axios";
import {date_formatter} from "../../../components/formatter";

const OperationHistory = ({url}) => {
    const [operations, setOperations] = useState([]);

    useEffect(() => {
        const getOperations = async () => {
            try {
                const response = await axios.get(`${url}/wallet/get-wallet-history`, {withCredentials: true})
                setOperations(response.data);
            } catch (error_response) {
                console.error('Error fetching operations data', error_response);
            }
        }
        getOperations();
    }, [url]);

    const operation_type_formatter = (operation) => {
        if (operation.type === "partner_program") {
            return (<span className="tooltip" style={{cursor: "pointer", borderBottom: "1px dashed #999"}}
                          data-tooltip={`Начисление по ${operation.details.level} ур. от партнёра ${operation.details.name}`}>Партнерская программа</span>);
        } else if (operation.type === "withdraw") {
            return <span>Вывод</span>
        } else if (operation.type === "subscribe_vps") {
            return <span>Подписка на VPS</span>
        } else if (operation.type === "transfer") {
            return <span>Перевод</span>
        } else if (operation.type === "subscribe_vpn") {
            return <span>Подписка на VPN</span>
        } else if (operation.type === "accrual") {
            return <span>Начисление</span>
        } else if (operation.type === "subscribe") {
            return <span>Подписка</span>
        }
    }

    return (
        <>
            <div className="operation-history">
                <table className="operation-history-table">
                    <thead>
                    <tr className="operation-history-table-head">
                        <th>Статус</th>
                        <th>Способ</th>
                        <th>Операция</th>
                        <th>Сумма</th>
                        <th>Дата</th>
                    </tr>
                    </thead>
                    <tbody>
                    {operations && (
                        operations.map((operation) => (
                            <tr key={operation.id} className="operation-history-table-row">
                                <td>
                                    <div className="operation-history-status">
                                        <span>{operation.status === "completed" ? "Завершена" : "Отменена"}</span>
                                        <div className="operation-history-tag">
                                        <span
                                            className="yellow-text">{operation.tag.slice(0, 3)}</span><span>{operation.tag.slice(3)}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>{operation.gateway === "cryptocurrency" ? "Криптовалюты" : "Внутренняя валюта"}</td>
                                <td>{operation_type_formatter(operation)}</td>
                                <td className={operation.direction === "credit" ? "red" : "green"}>{operation.direction === "credit" ? "-" : "+"}{(operation.amount - operation.comission) / 100} $</td>
                                <td className="operation-history-time">{date_formatter(operation.created_at)}</td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
                <div className="operation-history-column">
                    <ul className="operation-history-ul">
                        {operations && (
                            operations.map((operation) => (
                                <li key={operation.id} className="operation-history-item">
                                    <div className="operation-history-item-row">
                                    <span><span
                                        className="yellow-text">{operation.tag.slice(0, 3)}</span>{operation.tag.slice(3)}</span>
                                        <span
                                            className="operation-history-time">{date_formatter(operation.created_at)}</span>
                                    </div>
                                    <div className="operation-history-item-row">
                                    <span
                                        className={operation.direction === "credit" ? "red" : "green"}>{operation.direction === "credit" ? "-" : "+"}{(operation.amount - operation.comission) / 100} $</span>
                                        <span>{operation.status === "completed" ? "Завершена" : "Отменена"}</span>
                                    </div>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default OperationHistory;