import React, {useEffect, useState} from "react";
import "./Admin.css";
import axios from "axios";
import {money_formatter} from '../../../components/formatter';
import {useOutletContext} from "react-router-dom";

const Admin = ({setLocationAdmin, url}) => {
    const [allBalance, setAllBalance] = useState(null);
    const [statistic, setStatistic] = useState(null);
    const {userData} = useOutletContext() || {};

    useEffect(() => {
        const get_permissions = async () => {
            try {
                const response = await axios.get(`${url}/admin/get-all-balance`, {withCredentials: true})
                setAllBalance(response.data);
            } catch (error_response) {
                console.error('Error fetching all balance data', error_response);
            }
        }
        get_permissions()
    }, [url]);

    useEffect(() => {
        const get_statistic = async () => {
            try {
                const response = await axios.get(`${url}/admin/get-statistic`, {withCredentials: true})
                setStatistic(response.data);
            } catch (error_response) {
                console.error('Error fetching statistic data', error_response);
            }
        }
        get_statistic()
    }, [url]);

    useEffect(() => {
        setLocationAdmin("admin-base");
    }, [setLocationAdmin]);

    return (
        <>
            {userData !== {} ? (
                <section className="admin-base">
                    <h2 className="admin-base-title">Информационная панель</h2>
                    <div className="admin-base-row">
                        {/*<div className="admin-base-balance">*/}
                        {/*    <div className="admin-base-balance-row">*/}
                        {/*        <h3 className="admin-base-row-title">Баланс WW</h3>*/}
                        {/*        <span className="admin-base-row-info">2180.45 USDT</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="admin-base-balance">
                            <div className="admin-base-balance-row">
                                <h3 className="admin-base-row-title">Баланс пользователей</h3>
                                <span
                                    className="admin-base-row-info">$ {allBalance >= 0 ? allBalance / 100 : "loading..."}</span>
                            </div>
                        </div>
                    </div>
                    <div className="admin-base-statistics">
                        <div className="admin-base-statistics-column">
                            <h3 className="admin-base-row-title">Статистика за неделю</h3>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Кол-во счетов</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? statistic.count_of_accounts : "loading..."} шт.</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Сумма депозитов</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.sum_of_deposits / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Эквити $</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.sum_of_equity / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Маржинальность $</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.sum_of_margin_level / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за сегодня в $</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.profit_today_sum_cur / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за сегодня в %</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? statistic.profit_today_sum_per : "loading..."}%</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за вчера в $</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.profit_yesterday_sum_cur / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за вчера в %</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? statistic.profit_yesterday_sum_per : "loading..."}%</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за неделю в $</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.profit_week_sum_cur / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за неделю в %</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? statistic.profit_week_sum_per : "loading..."}%</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за месяц в $</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.profit_months_sum_cur / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за месяц в %</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? statistic.profit_months_sum_per : "loading..."}%</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за прошлый месяц в $</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.profit_prevmonths_sum_cur / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Прибыль за прошлый месяц в %</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? statistic.profit_prevmonths_sum_per : "loading..."}%</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Итоговая прибыль в $</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? money_formatter(statistic.profit_total_sum_cur / 100) : "loading..."}</span>
                            </div>
                            <div className="admin-base-statistics-column-row">
                                <h3 className="admin-base-statistics-row-title">Итоговая прибыль в %</h3>
                                <span
                                    className="admin-base-statistics-row-info">{statistic ? statistic.profit_total_sum_per : "loading..."}%</span>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section onClick={() => window.location.href = "/"} className="error-page">
                    <div className="container error-container">
                        <span className="error-page-title">404 | NOT FOUND</span>
                    </div>
                </section>
            )}
        </>
    )
}

export default Admin;