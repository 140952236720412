import {Link} from "react-router-dom";
import "./Notification.css";

const Notification2 = () => {
    return (
        <>
            <div className="notification-message">
                <span className="notification-message-title">Внимание!</span>
                <p className="notification-message-text">
                    На данный момент подключение к MetricaFX происходит через <Link
                    to="https://t.me/RoyalFamily_Support_bot"
                    className="notification-message-link">техническую
                    поддержку</Link>.
                    <br/>
                </p>
            </div>
        </>
    )
}

export default Notification2