import React, {useState} from "react";
import "./TradeCapitalReplacingAccountNumberScreen.css";
import {Link} from "react-router-dom";
import axios from "axios";

const TradeCapitalReplacingAccountNumberScreen = ({tCAccount, url}) => {
    const [newAccountNumber, setNewAccountNumber] = useState("");
    const [checkbox, setCheckbox] = useState(false);

    const change_tc_account_number = async () => {
        const error_block_2 = document.querySelector(".method-text-error.input2");
        const error_checkbox = document.querySelector(".method-text-error.checkbox-error");

        let hasErrors = false;

        if (newAccountNumber.length < 6) {
            error_block_2.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_2) {
            error_block_2.innerText = "";
        }

        // Проверка чекбокса
        if (!checkbox) {
            error_checkbox.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_checkbox.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/account/change-tc-account-number`, {
                    new_account_number: newAccountNumber,
                    account_number: tCAccount.account_number,
                }, {withCredentials: true});
                if (response.status === 200) {
                    window.location.href = `/trade-capital/account/${newAccountNumber}`;
                }
            } catch (error_response) {
                console.error('Error changing TC account_number', error_response);
            }
        }
    };

    return (
        <>
            <div className="replacing-account-number-column">
                <div className="wallet-address">
                    <span className="method-text">Новый номер счёта</span>
                    <input onChange={(event) => setNewAccountNumber(event.target.value)} type="text"
                           placeholder="Введите номер торгового счёта"
                           className="method-input-block-base"/>
                    <span className="method-text-error input2"></span>
                </div>
                <div className="checkbox-block">
                    <div className="checkbox-block-row">
                        <input type="checkbox" className="checkbox" checked={checkbox}
                               onChange={(event) => setCheckbox(event.target.checked)}/>
                        <span className="checkbox-text">Я принимаю <Link to="#" className="invite-friend-link">условия замены</Link> торгового счёта.</span>
                    </div>
                    <span className="method-text-error checkbox-error"></span>
                </div>
                <button className="method-of-replenishment-btn" onClick={change_tc_account_number}>Сменить</button>
            </div>
        </>
    )
}

export default TradeCapitalReplacingAccountNumberScreen;