import React, {useEffect, useState} from "react";
import "./AdminTariffEdit.css"
import {Link, useParams} from "react-router-dom";
import axios from "axios";

const AdminTariffEdit = ({setLocationAdmin, url}) => {
    const [tariff, setTariff] = useState(null);
    const {id} = useParams();
    const error_text = document.querySelector(".error");
    const [status, setStatus] = useState(false)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [price, setPrice] = useState(0)
    const [months, setMonths] = useState(0)
    const [discount, setDiscount] = useState(false)
    const [discountedPrice, setDiscountedPrice] = useState(0)
    const [referalLevels, setReferalLevels] = useState(1);
    const [robotReplacing, setRobotReplacing] = useState(false);
    const [rewardForSales, setRewardForSales] = useState(false);
    const [image, setImage] = useState(null);

    const update_tariff = async () => {
        try {
            const formData = new FormData();
            formData.append("subscribe_id", id);
            formData.append("enabled", status);
            formData.append("name", title);
            formData.append("description", description)
            formData.append("months", months);
            formData.append("price", price);
            formData.append("sale_price", discountedPrice);
            formData.append("sale_enabled", discount);
            formData.append("referal_levels", referalLevels);
            formData.append("robot_replacing", robotReplacing);
            formData.append("reward_for_sales", rewardForSales);
            if (image) {
                formData.append("image", image);
            }
            const response = await axios.post(`${url}/admin/update-subscribe`,
                formData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
            await get_subscribe();
        } catch (error_response) {
            console.error('Error updating subscribe', error_response);
            console.log(error_response.response.data)
            error_text.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка!";
        }
    };

    const get_subscribe = async () => {
        try {
            const response = await axios.get(`${url}/admin/get-subscribe?subscribe_id=${id}`,
                {withCredentials: true})
            setTariff(response.data);
            if (response.status === 200) {
                setStatus(response.data.enabled);
                setTitle(response.data.name);
                setDescription(response.data.description);
                setMonths(response.data.months);
                setPrice(response.data.price);
                setDiscountedPrice(response.data.sale_price);
                setDiscount(response.data.sale_enabled);
            }
        } catch (error_response) {
            error_text.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка!";
            console.error('Error fetching subscribe', error_response);
        }
    }

    useEffect(() => {
        get_subscribe();
    }, [error_text, url]);

    useEffect(() => {
        setLocationAdmin("admin-tariff-edit")
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Редактирование тарифа</h2>
                <Link to="/admin/tariffs" className="experts-product-back-btn">
                    <i className="fa fa-chevron-left"></i>
                    назад
                </Link>
                <div className="admin-edit-block rf-grey-block">
                    <h3 className="admin-edit-title">Форма редактирования</h3>
                    {tariff && (
                        <>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Статус:</label>
                                <div className="admin-edit-checkbox">
                                    <label className="switch">
                                        <input type="checkbox" checked={status} onChange={() => setStatus(!status)}/>
                                        <span className="slider"></span>
                                    </label>
                                    <span className="admin-edit-checkbox-text">Вкл.</span>
                                </div>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Цена:</label>
                                <input type="number" value={+price / 100}
                                       onChange={(event) => setPrice(+event.target.value * 100)}
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Кол-во месяцев:</label>
                                <input type="number" value={months} onChange={(event) => setMonths(+event.target.value)}
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Название:</label>
                                <input type="text" value={title} onChange={(event) => setTitle(event.target.value)}
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Описание:</label>
                                <textarea value={description} onChange={(event) => setDescription(event.target.value)}
                                          className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Скидка:</label>
                                <div className="admin-edit-checkbox">
                                    <label className="switch">
                                        <input type="checkbox" checked={discount}
                                               onChange={() => setDiscount(!discount)}/>
                                        <span className="slider"></span>
                                    </label>
                                    <span className="admin-edit-checkbox-text">Вкл.</span>
                                </div>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Цена со скидкой:</label>
                                <input type="text" value={+discountedPrice / 100}
                                       onChange={(event) => setDiscountedPrice(+event.target.value * 100)}
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Реферальные уровни:</label>
                                <input type="text" value={+referalLevels}
                                       onChange={(event) => setReferalLevels(+event.target.value)}
                                       className="admin-filter-input"/>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Замена робота:</label>
                                <div className="admin-edit-checkbox">
                                    <label className="switch">
                                        <input type="checkbox" checked={robotReplacing}
                                               onChange={() => setRobotReplacing(!robotReplacing)}/>
                                        <span className="slider"></span>
                                    </label>
                                    <span className="admin-edit-checkbox-text">Вкл.</span>
                                </div>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label className="admin-edit-label">Бонус за продажи</label>
                                <div className="admin-edit-checkbox">
                                    <label className="switch">
                                        <input type="checkbox" checked={rewardForSales}
                                               onChange={() => setRewardForSales(!rewardForSales)}/>
                                        <span className="slider"></span>
                                    </label>
                                    <span className="admin-edit-checkbox-text">Вкл.</span>
                                </div>
                            </div>
                            <div className="admin-edit-checkbox-block">
                                <label
                                    className="admin-edit-label">Изображение: {tariff && tariff.image ? tariff.image.filename : null}</label>
                                <input type="file"
                                       onChange={(event) => setImage(event.target.files[0])}
                                       className="admin-filter-input"/>
                            </div>
                            <span className="error"></span>
                            <button onClick={() => update_tariff()}
                                    className="admin-checking-account-number-btn admin-filter-apply">Сохранить
                            </button>
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default AdminTariffEdit;