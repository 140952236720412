import React, {useEffect, useState} from "react";
import "./ExpertBuy.css";
import Notification from "../../../components/Notification/Notification";
import {Link} from "react-router-dom";
import axios from "axios";

const ExpertBuy = ({setLocation, url}) => {
    const [tariffs, setTariffs] = useState(null);

    const buy_subscription = async (price) => {
        const error_span = document.querySelector(".method-text-error.checkbox-error2");
        let hasErrors = false;

        if (price <= 0) {
            error_span.innerText = "Необходимо выбрать тариф";
            hasErrors = true;
        } else {
            error_span.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/subscribe/buy-subscription?price=${price}`,
                    {},
                    {withCredentials: true})
                setTariffs(response.data);
                window.location = "/experts";
            } catch (error_response) {
                console.error('Error fetching tariffs data', error_response);
                console.log(error_response);
                error_span.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка.";
            }
        }
    };

    useEffect(() => {
        setLocation("experts-buy");
    }, [setLocation]);

    useEffect(() => {
        const getTariffsData = async () => {
            try {
                const response = await axios.get(`${url}/subscribe/get-subscribes`, {withCredentials: true})
                setTariffs(response.data.sort((a, b) => a.months - b.months));
            } catch (error_response) {
                console.error('Error fetching tariffs data', error_response);
            }
        }
        getTariffsData();
    }, [url]);

    return (
        <>
            <section className="experts-product">
                <div className="experts-product-column">
                    <Notification/>
                    <Link to="/experts" className="experts-product-back-btn">
                        <i className="fa fa-chevron-left"></i>
                        назад
                    </Link>
                    <div className="my-trading-system">
                        <div className="my-trading-system-header">
                            <h2 className="my-trading-system-title">Выбрать торговую систему</h2>
                            <h3 className="my-trading-system-subtitle">Выберите свою торговую систему</h3>
                            <span className="method-text-error checkbox-error2"></span>
                        </div>
                    </div>
                    <ul className="trading-system-buy-new-list">
                        {tariffs && tariffs.map((item) => (
                            <li key={item.id} className="trading-system-buy-new-item">
                                <div className="trading-system-buy-new-poster active">
                                    <h3 className="trading-system-buy-new-title">{item.name}</h3>
                                    <div className="trading-system-buy-new-poster-info">
                                    </div>
                                    <img src={item.image && `${url}/static/${item.image.files[0]}`} alt="Tariff Image"
                                         className="trading-system-buy-new-poster-img"/>
                                </div>
                                <div className="trading-system-buy-new-info">
                                    <div className="trading-system-buy-new-prise-block">
                                        {!item.sale_enabled ? (
                                            <div className="trading-system-buy-new-prise">
                                                <span
                                                    className="experts-buy-tariff-prise">{item.price / 100} $</span>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="trading-system-buy-new-prise">
                                                    <div style={{display: 'flex'}}>
                                                        <span className="experts-buy-tariff-prise"
                                                              style={{marginLeft: '6px'}}>{item.sale_price / 100} $</span>
                                                        <span
                                                            className="trading-system-buy-new-sale">{item.price / 100} $</span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="trading-system-buy-new-description-block">
                                        {`${item.description}`}
                                    </div>
                                    <button onClick={() => buy_subscription(item.price)}
                                            className="trading-system-buy-new-btn">Купить
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </>
    )
}

export default ExpertBuy;