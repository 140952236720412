import React, {useEffect, useState} from "react";
import "./Experts.css";
import Notification from "../../components/Notification/Notification";
import {Link} from "react-router-dom";
import axios from "axios";
import {date_formatter} from "../../components/formatter";

const Experts = ({setLocation, url}) => {
    const [selectedLink, setSelectedLink] = useState("active")
    const [accountsData, setAccountsData] = useState(null);

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".experts-nav-btn");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
        });

        event.target.classList.add("active");
    }
    useEffect(() => {
        setLocation("experts");
    }, [setLocation]);

    useEffect(() => {
        const getAccountsData = async () => {
            try {
                const response = await axios.get(`${url}/account/get-accounts`, {withCredentials: true})
                setAccountsData(response.data);
            } catch (error_response) {
                console.error('Error fetching accounts data', error_response);
            }
        }
        getAccountsData();
    }, [url]);

    return (
        <>
            <section className="experts">
                <div className="experts-column">
                    <Notification/>
                    <div className="hero experts-hero">
                        <div className="hero-row">
                            <div className="experts-info">
                                <h2 className="experts-info-title">Торговые системы</h2>
                                <span className="experts-info-text">Используйте всю мощь алгоритмов торговых систем Royal Family!</span>
                                <Link className="experts-info-link" to="/experts/buy">Купить торговую систему</Link>
                            </div>
                            <img src="/experts-active.svg" alt="Partners" className="experts-img"/>
                        </div>
                    </div>
                    <ul className="experts-nav-list">
                        <li className="experts-nav-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setSelectedLink("active");
                            }}
                                    className="experts-nav-btn active">Активированные подписки
                            </button>
                        </li>
                        <li className="experts-nav-item">
                            <button onClick={(event) => {
                                active_button(event);
                                setSelectedLink("not active");
                            }}
                                    className="experts-nav-btn">Не активированные подписки
                            </button>
                        </li>
                    </ul>
                    {selectedLink === "active" ? (
                        <>
                            {accountsData ? (
                                <>
                                    {accountsData.active.length > 0 ? (
                                        <table className="partners-table">
                                            <thead>
                                            <tr className="partners-table-head">
                                                <th>Продукт</th>
                                                <th>Номер счета</th>
                                                <th>Истекает</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {accountsData.active.map((account) => (
                                                <tr key={account.id} className="experts-table-row">
                                                    <td>
                                                        <div className="experts-table-th-row">
                                                            <span
                                                                className="experts-table-img">{account.product.name.slice(0, 1)}</span>
                                                            {account.product.name}
                                                        </div>
                                                    </td>
                                                    <td>{account.account_number}</td>
                                                    <td>
                                                        <div className="experts-table-th-column">
                                                            <span>{date_formatter(account.expires_at)}</span>
                                                            {new Date(account.expires_at) < new Date() ? (
                                                                <span
                                                                    className="red-text">истекла</span>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link to={`/experts/product/${account.account_number}`}
                                                              className="yellow-text experts-yellow-text">подробнее</Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="experts-blocks-row">
                                            <div className="metricafx-advantages-card">
                                                <div className="advantages-card-img-container">
                                                    <img src="/function.svg" alt="Function"
                                                         className="advantages-card-img"/>
                                                </div>
                                                <h3 className="advantages-card-title">Уникальные алгоритмы</h3>
                                                <span className="advantages-card-description">В наших торговых советниках используются уникальные алгоритмы, позволяющие грамотно анализировать рынок.</span>
                                            </div>
                                            <div className="metricafx-advantages-card">
                                                <div className="advantages-card-img-container">
                                                    <img src="/shield.svg" alt="Function"
                                                         className="advantages-card-img"/>
                                                </div>
                                                <h3 className="advantages-card-title">Надёжность</h3>
                                                <span className="advantages-card-description">Выбирай и пользуйся теми инструментами, которыми ты хочешь - все они доступны в рамках одной подписки.</span>
                                            </div>
                                            <div className="metricafx-advantages-card">
                                                <div className="advantages-card-img-container">
                                                    <img src="/medal.svg" alt="Function"
                                                         className="advantages-card-img"/>
                                                </div>
                                                <h3 className="advantages-card-title">Безотказность</h3>
                                                <span className="advantages-card-description">Наши специалисты на связи и готовы помочь тебе в установке и настройке. Ты не останешься один на один со своей проблемой.</span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            {accountsData && accountsData.not_active.length > 0 ? (
                                <>
                                    <table className="partners-table">
                                        <thead>
                                        <tr className="partners-table-head">
                                            <th>Истекает</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {accountsData.not_active.map((account) => (
                                            <tr key={account.id} className="experts-table-row">
                                                <td>
                                                    <div className="experts-table-th-column">
                                                        <span>{date_formatter(account.expires_at)}</span>
                                                        {new Date(account.expires_at) < new Date() && (
                                                            <span className="red-text">истекла</span>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    {new Date(account.expires_at) > new Date() && (
                                                        <Link to={`/experts/activation/${account.id}`}
                                                              className="yellow-text experts-yellow-text">активировать</Link>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </>
                            ) : <span>Нет подписок</span>}
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default Experts;