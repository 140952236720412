import React, {useEffect, useState} from "react";
import "./AdminProductAdd.css";
import {Link} from "react-router-dom";
import MarkdownEditor from "../../../../components/MarkdownEditor/MarkdownEditor";
import axios from "axios";

const AdminProductAdd = ({setLocationAdmin, url}) => {
    const [status, setStatus] = useState(false)
    const [isDownloadable, setIsDownloadable] = useState(false)
    const [title, setTitle] = useState("")
    const [serviceKey, setServiceKey] = useState("")
    const [version5, setVersion5] = useState("")
    const [fileMt5, setfileMt5] = useState(null);
    const [version4, setVersion4] = useState("")
    const [fileMt4, setfileMt4] = useState(null);
    const [description, setDescription] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("MetricaFX")
    const error_text = document.querySelector(".error");

    const create_product = async () => {
        let hasErrors = false;

        // Проверка поля cryptowallet
        if (title.length < 1) {
            error_text.innerText = "Поле название не заполнено";
            hasErrors = true;
        } else if (error_text) {
            error_text.innerText = "";
        }

        // Проверка поля cryptocurrency
        if (serviceKey < 1) {
            error_text.innerText = "Поле ключ не заполнено";
            hasErrors = true;
        } else if (error_text) {
            error_text.innerText = "";
        }

        if (version5 < 1) {
            error_text.innerText = "Поле версия mt5 не заполнено";
            hasErrors = true;
        } else if (error_text) {
            error_text.innerText = "";
        }

        if (version4 < 1) {
            error_text.innerText = "Поле версия mt4 не заполнено";
            hasErrors = true;
        } else if (error_text) {
            error_text.innerText = "";
        }

        if (!fileMt5) {
            error_text.innerText = "Необходимо загрузить файл советника mt5";
            hasErrors = true;
        } else {
            error_text.innerText = "";
        }

        // Проверка чекбокса
        if (!fileMt4) {
            error_text.innerText = "Необходимо загрузить файл советника mt4";
            hasErrors = true;
        } else {
            error_text.innerText = "";
        }

        if (!hasErrors) {
            try {
                const formData = new FormData();
                formData.append("enabled", status);
                formData.append("is_downloadable", isDownloadable);
                formData.append("name", title);
                formData.append("description", description)
                formData.append("service_key", serviceKey);
                formData.append("version", version5);
                formData.append("mt4_version", version4);
                formData.append("category_name", selectedCategory);
                formData.append("expert_mt5", fileMt5);
                formData.append("expert_mt4", fileMt4);
                const response = await axios.post(`${url}/admin/add-product`,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                if (response.status === 200) {
                    window.location.href = "/admin/products";
                }
            } catch (error_response) {
                console.error('Error sending money', error_response);
                console.log(error_response.response.data)
                error_text.innerText = error_response.response ? error_response.response.data.detail : "Неизвестная ошибка!";
            }
        }
    };

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    useEffect(() => {
        setLocationAdmin("admin-product-add")
    }, [setLocationAdmin])
    return (
        <>
            <section className="admin-base">
                <h2 className="admin-base-title">Добавление продукта</h2>
                <Link to="/admin/products" className="experts-product-back-btn">
                    <i className="fa fa-chevron-left"></i>
                    назад
                </Link>
                <div className="admin-edit-block rf-grey-block">
                    <h3 className="admin-edit-title">Форма редактирования</h3>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Статус:</label>
                        <div className="admin-edit-checkbox">
                            <label className="switch">
                                <input type="checkbox" onChange={() => setStatus(!status)}/>
                                <span className="slider"></span>
                            </label>
                            <span className="admin-edit-checkbox-text">Вкл.</span>
                        </div>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Включить скачивание:</label>
                        <div className="admin-edit-checkbox">
                            <label className="switch">
                                <input type="checkbox" onChange={() => setIsDownloadable(!isDownloadable)}/>
                                <span className="slider"></span>
                            </label>
                            <span className="admin-edit-checkbox-text">Да</span>
                        </div>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Название:</label>
                        <input type="text"
                               onChange={(event) => setTitle(event.target.value)}
                               className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Ключ:</label>
                        <input type="text"
                               onChange={(event) => setServiceKey(event.target.value)}
                               className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Версия MT5:</label>
                        <input type="text"
                               required
                               onChange={(event) => setVersion5(event.target.value)}
                               className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">MT5:</label>
                        <input type="file"
                               onChange={(event) => setfileMt5(event.target.files[0])}
                               className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Версия MT4:</label>
                        <input type="text"
                               required
                               onChange={(event) => setVersion4(event.target.value)}
                               className="admin-filter-input"/>
                    </div>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">MT4:</label>
                        <input type="file"
                               onChange={(event) => setfileMt4(event.target.files[0])}
                               className="admin-filter-input"/>
                    </div>
                    <MarkdownEditor description={description} setDescription={setDescription}/>
                    <div className="admin-edit-checkbox-block">
                        <label className="admin-edit-label">Категория:</label>
                        <button onClick={open_options}
                                className="admin-filter-input admin-filter-select">{selectedCategory}</button>
                        <div className="method-options-block method-options-mt4-block">
                            <ul className="method-options-list">
                                <li className="method-options-item" onClick={() => {
                                    setSelectedCategory("MetricaFX");
                                    open_options();
                                }}>MetricaFX
                                </li>
                                <li className="method-options-item" onClick={() => {
                                    setSelectedCategory("Торговый советник");
                                    open_options();
                                }}>Торговый советник
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span className="error"></span>
                    <button onClick={() => create_product()}
                            className="admin-checking-account-number-btn admin-filter-apply">Сохранить
                    </button>
                </div>
            </section>
        </>
    )
}

export default AdminProductAdd;