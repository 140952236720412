const date_formatter = (dateStr) => {
    const date = new Date(dateStr);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} в ${hours}:${minutes}`;
};

const money_formatter = (amount) => {
  return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export { date_formatter, money_formatter };
