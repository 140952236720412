import React, { useEffect, useRef, useState } from 'react';
import EasyMDE from 'easymde';
import 'easymde/dist/easymde.min.css';
import {marked} from "marked";

const MarkdownEditor = ({description, setDescription}) => {
    const [value, setValue] = useState(description ? description : "");
    const [preview, setPreview] = useState('');
    const textAreaRef = useRef(null);
    const easyMDERef = useRef(null);

    useEffect(() => {
        setDescription(value)
    }, [value])

    useEffect(() => {
        easyMDERef.current = new EasyMDE({
            element: textAreaRef.current,
            initialValue: value,
            autoDownloadFontAwesome: true,
            spellChecker: false,
            toolbar: [
                {
                    name: "bold",
                    action: () => insertMarkdownTag('<b>', '</b>'),
                    className: "fa fa-bold",
                    title: "Bold",
                },
                {
                    name: "italic",
                    action: () => insertMarkdownTag('<i>', '</i>'),
                    className: "fa fa-italic",
                    title: "Italic",
                },
                {
                    name: "unordered-list",
                    action: () => insertList(),
                    className: "fa fa-list-ul",
                    title: "Unordered List",
                },
                {
                    name: "ordered-list",
                    action: () => insertOrderedList(),
                    className: "fa fa-list-ol",
                    title: "Ordered List",
                },
                {
                    name: "color",
                    action: () => insertColoredText(),
                    className: "fa fa-paint-brush",
                    title: "Text Color",
                },
                "|",
                "guide"
            ],
        });

        // Обновляем состояние при изменении содержимого редактора
        easyMDERef.current.codemirror.on('change', () => {
            const currentValue = easyMDERef.current.value();
            setValue(marked(currentValue));
            setPreview(marked(currentValue)); // Преобразование Markdown в HTML
        });

        return () => {
            easyMDERef.current.toTextArea();
        };
    }, []);

    const insertMarkdownTag = (tag1, tag2) => {
        const selection = easyMDERef.current.codemirror.getSelection();
        if (selection) {
            const wrapped = `${tag1}${selection}${tag2}`;
            easyMDERef.current.codemirror.replaceSelection(wrapped);
        } else {
            easyMDERef.current.codemirror.replaceSelection(tag1 + tag2);
        }
    };

    const insertColoredText = () => {
        const color = prompt("Enter a color (e.g., red, #ff0000):");
        const selection = easyMDERef.current.codemirror.getSelection();
        if (selection) {
            const coloredText = `<span style="color: ${color};">${selection}</span>`;
            easyMDERef.current.codemirror.replaceSelection(coloredText);
        } else {
            const coloredText = `<span style="color: ${color};"></span>`;
            easyMDERef.current.codemirror.replaceSelection(coloredText);
        }
    };

    // Функция для вставки списка
    const insertList = () => {
        const selection = easyMDERef.current.codemirror.getSelection();
        let items;

        if (selection) {
            // Разбиваем выделенный текст на строки и формируем список
            items = selection.split('\n').map(item => `<li>${item}</li>`).join('\n');
            items = `<ul>\n${items}\n</ul>`;
            easyMDERef.current.codemirror.replaceSelection(items);
        } else {
            // Если ничего не выделено, вставляем пустой список
            easyMDERef.current.codemirror.replaceSelection('<ul>\n<li style="list-style-type: disc"></li>\n</ul>');
        }
    };

    const insertOrderedList = () => {
        const selection = easyMDERef.current.codemirror.getSelection();
        let items;

        if (selection) {
            // Разбиваем выделенный текст на строки и формируем список
            items = selection.split('\n').map(item => `<li>${item}</li>`).join('\n');
            items = `<ol>\n${items}\n</ol>`;
            easyMDERef.current.codemirror.replaceSelection(items);
        } else {
            // Если ничего не выделено, вставляем пустой список
            easyMDERef.current.codemirror.replaceSelection('<ol>\n<li></li>\n</ol>');
        }
    };

    return (
        <div>
            <textarea ref={textAreaRef} />
            {/*<span dangerouslySetInnerHTML={{ __html: preview }}/>*/}
        </div>
    );
};

export default MarkdownEditor;