import React, {useEffect, useState} from "react";
import "./replenishment-wallet.css"
import {Link} from "react-router-dom";
import axios from "axios";

const ReplenishmentWallet = ({userData, url}) => {
    const [method, setMethod] = useState("");
    const [cryptocurrency, setCryptocurrency] = useState("");
    const [checkbox, setCheckbox] = useState(false);
    const [walletData, setWalletData] = useState(null);

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const open_cryptocurrencies = () => {
        const block = document.querySelector(".cryptocurrencies-options-block")
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const top_up_with_cryptocurrency = async () => {
        const error_block = document.querySelector(".method-text-error.input");
        const error_block_2 = document.querySelector(".method-text-error.input2");
        const error_checkbox = document.querySelector(".method-text-error.checkbox-error");

        let hasErrors = false;

        // Проверка поля method
        if (method.length === 0) {
            error_block.innerText = "Поле не заполнено";
            hasErrors = true;
        } else {
            error_block.innerText = "";
        }

        // Проверка поля cryptocurrency
        if (method.length > 0 && cryptocurrency.length === 0) {
            error_block_2.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_2) {
            error_block_2.innerText = "";
        }

        // Проверка чекбокса
        if (!checkbox) {
            error_checkbox.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_checkbox.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.get(`${url}/wallet/create-payment`,
                    {withCredentials: true})
                if (response.status === 200) {
                    setWalletData(response.data);
                }
            } catch (error_response) {
                console.error('Error getting payment info', error_response);
            }
        }
    };

    const get_payment_info = async () => {
            try {
                const response = await axios.get(`${url}/wallet/get-payment-info`,
                    {withCredentials: true})
                if (response.status === 200) {
                    setWalletData(response.data);
                }
            } catch (error_response) {
                console.error('Error getting payment info', error_response);
            }
        }

    const cancel_payment = async () => {
        try {
            const response = await axios.post(`${url}/wallet/cancel-payment`,
                {},
                {withCredentials: true})
            if (response.status === 200) {
                await get_payment_info();
            }
        } catch (error_response) {
            console.error('Error cancellation of the refund', error_response);
        }
    }

    useEffect(() => {
        get_payment_info();
    }, [url]);

    return (
        <>
            <div className="method-of-replenishment">
                {walletData ? (
                    <div className="wallet-withdrawal-status-column">
                        <ul className="wallet-withdrawal-status-info">
                            <li className="wallet-withdrawal-status-row">
                                <span className="wallet-withdrawal-status-title">Статус</span>
                                <span>{walletData.status === "new" ? "Новая" : walletData.status}</span>
                            </li>
                            <li className="wallet-withdrawal-status-row">
                                <span className="wallet-withdrawal-status-title">Способ оплаты</span>
                                <span>{walletData.gateway === "cryptocurrency" ? "Криптовалюты" : walletData.gateway}</span>
                            </li>
                            <li className="wallet-withdrawal-status-row">
                                <span className="wallet-withdrawal-status-title">Валюта</span>
                                <span>{walletData.details.currency}</span>
                            </li>
                            <li className="wallet-withdrawal-status-row">
                                <span className="wallet-withdrawal-status-title">Минимальный платеж</span>
                                <span>15 USDT</span>
                            </li>
                            <li className="wallet-withdrawal-status-row">
                                <span className="wallet-withdrawal-status-title">Комиссия шлюза</span>
                                <span>{walletData.comission}</span>
                            </li>
                            <li className="wallet-withdrawal-status-row">
                                <span className="wallet-withdrawal-status-title">Полученная сумма</span>
                                <span>{walletData.amount}</span>
                            </li>
                            <li className="wallet-withdrawal-status-row">
                                <span>{walletData.details.address}</span>
                            </li>
                        </ul>
                        <span className="small-text">Временный кошелёк для пополнения действует 60 минут.</span>
                        <button onClick={async () => await cancel_payment()}
                                className="method-of-replenishment-btn">Отменить операцию
                        </button>
                    </div>
                ) : (
                    <div className="method-of-replenishment-first-column">
                        <div className="method">
                            <span className="method-text">Способ пополнения</span>
                            <div className="method-input-block-base">
                                <div className="method-input-block" onClick={open_options}>
                                <span
                                    className="method-input-placeholder">{method.length > 0 ? method : "Выберите способ оплаты"}</span>
                                    <i className="fa fa-chevron-down"></i>
                                </div>
                                <div className="method-options-block">
                                    <ul className="method-options-list">
                                        <li className="method-options-item"
                                            onClick={() => {
                                                setMethod("Криптовалюты");
                                                open_options();
                                            }}>Криптовалюты
                                        </li>
                                        <li className="method-options-item none">Банковская карта (скоро)</li>
                                    </ul>
                                </div>
                            </div>
                            <span className="method-text-error input"></span>
                        </div>
                        {method === "Криптовалюты" && (
                            <div className="method">
                                <span className="method-text">Криптовалюты</span>
                                <div className="method-input-block-base">
                                    <div className="method-input-block" onClick={open_cryptocurrencies}>
                                    <span
                                        className="method-input-placeholder">{cryptocurrency.length > 0 ? cryptocurrency : "Выберите"}</span>
                                        <i className="fa fa-chevron-down"></i>
                                    </div>
                                    <div className="cryptocurrencies-options-block">
                                        <ul className="method-options-list">
                                            <li className="method-options-item" onClick={() => {
                                                setCryptocurrency("USDT TRC-20")
                                                open_cryptocurrencies()
                                            }}>USDT TRC-20
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span className="method-text-error input2"></span>
                            </div>
                        )}
                        <div className="checkbox-block">
                            <div className="checkbox-block-row">
                                <input type="checkbox" className="checkbox" checked={checkbox}
                                       onChange={(event) => setCheckbox(event.target.checked)}/>
                                <span className="checkbox-text">Я принимаю <Link to="#" className="invite-friend-link">условия пополнения</Link> с помощью криптовалют.</span>
                            </div>
                            <span className="method-text-error checkbox-error"></span>
                        </div>
                        <span className="small-text">Временный кошелёк для пополнения действует 60 минут.</span>
                        <button className="method-of-replenishment-btn" onClick={top_up_with_cryptocurrency}>Пополнить
                            криптовалютой
                        </button>
                    </div>
                )}
                <div className="method-of-replenishment-second-column">
                    <iframe src="https://www.youtube.com/embed/utgSNBCwZeY?si=Uq1LVt3Ap38u2mkS"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            className="method-of-replenishment-video">
                    </iframe>
                </div>
            </div>
        </>
    )
}

export default ReplenishmentWallet