import React, {useEffect} from "react";
import Notification from "../../../components/Notification/Notification";
import "./PartnersInvite.css";
import {useOutletContext} from "react-router-dom";

const PartnersInvite = ({setLocation}) => {
    const {userData, balance} = useOutletContext() || {};

    const {
        referal_link,
    } = userData || {};

    useEffect(() => {
        setLocation("partners_invite");
    }, [setLocation]);

    return (
        <>
            {userData && (
                <section className="partners-invite">
                    <div className="partners-column">
                        <Notification/>
                        <div className="hero partners">
                            <div className="hero-row partners">
                                <div className="partners-explanation">
                                    <h2 className="partners-title">Пригласить друга</h2>
                                    <span className="partners-description">
                                    Пригласите своих друзей и получите<br/>ещё больше прибыли работая вместе
                                </span>
                                </div>
                                <img src="/user-plus.svg" alt="User plus" className="partners-img"/>
                            </div>
                        </div>
                        <div className="hero partners invite-description">
                            Здесь отображается Ваша уникальная реферальная ссылка, которую вы можете использовать для
                            приглашения друзей и знакомых. Каждый, кто зарегистрируется по вашей ссылке, принесет вам
                            дополнительные бонусы и возможности. Поделитесь ссылкой и начните получать выгоду от
                            привлечения
                            новых пользователей уже сейчас!
                        </div>
                        <div className="partners-input-wrapper">
                            <label>Ваша реферальная ссылка</label>
                            <input id="referralLink" className="partners-input"
                                   value={`https://royalfamily.club/register?ref=${referal_link}`}
                                   readOnly=""/>
                            <small className="partners-input-smalltext">Нажмите на ссылку чтобы скопировать её</small>
                        </div>
                        <span className="partners-text">
                        В нашем клубе действует <span className="yellow-text">трехуровневая партнерская программа</span>, которая предоставляет вам возможность получать щедрые вознаграждения за рекомендацию наших продуктов.
                    </span>
                        <span className="partners-text">
                        С помощью нашей программы вы можете зарабатывать до <span className="yellow-text">50%</span> от стоимости продукта или подписки, которые будут приобретены по вашей рекомендации.</span>
                        <div className="how-this-work">
                            <h2 className="how-this-work-title">Вот как это работает:</h2>
                            <span className="how-this-work-description">С первого уровня вы получаете <span
                                className="yellow-text">30%</span> от стоимости продукта.</span>
                            <span className="how-this-work-description">Со второго уровня вы получаете <span
                                className="yellow-text">15%</span> от стоимости продукта.</span>
                            <span className="how-this-work-description">С третьего уровня вы получаете <span
                                className="yellow-text">5%</span> от стоимости продукта.</span>
                        </div>
                        <span className="partners-text">Рекомендуя наши качественные продукты своим друзьям, знакомым и коллегам, вы можете получать значительные доходы. Присоединяйтесь к нашей партнерской программе уже сегодня и начните зарабатывать!</span>
                    </div>
                </section>
            )}
        </>
    )
}

export default PartnersInvite;