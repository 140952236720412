import React, {useEffect, useState} from "react";
import "./ExpertsActivation.css";
import Notification from "../../../components/Notification/Notification";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

const ExpertsActivation = ({setLocation, url}) => {
    const [products, setProducts] = useState([]);
    const [broker, setBroker] = useState("");
    const [serviceKey, setServiceKey] = useState("");
    const {account_id} = useParams();
    const [newAccountNumber, setNewAccountNumber] = useState("");
    const [checkbox, setCheckbox] = useState(false)
    
    const error_checkbox = document.querySelector(".method-text-error.checkbox-error");

    const open_options = () => {
        const block = document.querySelector(".method-options-block");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const open_options2 = () => {
        const block = document.querySelector(".method-options-block2");
        if (block.classList.contains("open")) {
            block.classList.remove("open");
        } else {
            block.classList.add("open");
        }
    }

    const activate_account = async () => {
        const error_block = document.querySelector(".method-text-error.input");
        const error_block_2 = document.querySelector(".method-text-error.input2");
        const error_block_3 = document.querySelector(".method-text-error.input3");

        let hasErrors = false;

        // Проверка поля method
        if (broker.length === 0) {
            error_block.innerText = "Поле не заполнено";
            hasErrors = true;
        } else {
            error_block.innerText = "";
        }

        if (newAccountNumber.length < 8) {
            error_block_2.innerText = "Поле не заполнено";
            hasErrors = true;
        } else if (error_block_2) {
            error_block_2.innerText = "";
        }

        if (serviceKey.length < 3) {
            error_block_3.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_block_3.innerText = "";
        }

        // Проверка чекбокса
        if (!checkbox) {
            error_checkbox.innerText = "Поле пропущено";
            hasErrors = true;
        } else {
            error_checkbox.innerText = "";
        }

        if (!hasErrors) {
            try {
                const response = await axios.post(`${url}/account/change-account-number`, {
                    broker: broker,
                    new_account_number: newAccountNumber,
                    account_id: account_id,
                    service_key: serviceKey,
                }, {withCredentials: true});
                console.log(response.status);
                if (response.status === 200) {
                    window.location.href = `/experts`;
                }
            } catch (error_response) {
                error_checkbox.innerText = error_response ? error_response.response.data.detail : "Неизвестная ошибка.";
                console.error('Error product activation', error_response);
            }
        }
    };

    const selectProduct = (event, item) =>{
        const placeHolder = document.querySelector(".method-input-placeholder.product");
        placeHolder.innerText = item.name;
        setServiceKey(item.service_key);
        open_options2();
    }

    useEffect(() => {
        const get_products = async () => {
            try {
                const response = await axios.get(`${url}/product/get-products`, {withCredentials: true});
                setProducts(response.data.sort((a, b) => a.name.localeCompare(b.name)));
            } catch (error_response) {
                error_checkbox.innerText = error_response ? error_response.response.data.detail : "Неизвестная ошибка.";
                console.error('Error product activation', error_response);
            }
        }
        get_products();
    }, [url]);

    useEffect(() => {
        setLocation("experts-activation");
    }, [setLocation]);

    return (
        <>
            <section className="experts-product">
                <div className="experts-product-column">
                    <Notification/>
                    <Link to="/experts" className="experts-product-back-btn">
                        <i className="fa fa-chevron-left"></i>
                        назад
                    </Link>
                    <div className="my-trading-system">
                        <div className="my-trading-system-header">
                            <h2 className="my-trading-system-title">Активация торговой системы</h2>
                            <h3 className="my-trading-system-subtitle">Заполните данные, чтобы активировать торговую
                                систему</h3>
                        </div>
                    </div>
                    <div className="replacing-account-number-column">
                        <div className="method">
                            <span className="method-text">Брокер</span>
                            <div className="method-input-block-base">
                                <div className="method-input-block" onClick={open_options}>
                                <span
                                    className="method-input-placeholder">{broker.length > 0 ? broker : "Выберите брокера"}</span>
                                    <i className="fa fa-chevron-down"></i>
                                </div>
                                <div className="method-options-block">
                                    <ul className="method-options-list">
                                        <li className="method-options-item"
                                            onClick={() => {
                                                setBroker("Roboforex");
                                                open_options();
                                            }}>Roboforex
                                        </li>
                                        <li className="method-options-item" onClick={() => {
                                            setBroker("Forex4You");
                                            open_options();
                                        }}>Forex4You
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <span className="method-text-error input"></span>
                        </div>
                        <div className="wallet-address">
                            <span className="method-text">Номер счёта</span>
                            <input onChange={(event) => setNewAccountNumber(event.target.value)} type="text"
                                   placeholder="Введите номер торгового счёта"
                                   className="method-input-block-base"/>
                            <span className="method-text-error input2"></span>
                        </div>
                        <span className="method-text">Продукт</span>
                        <div className="method-input-block-base">
                            <div className="method-input-block" onClick={open_options2}>
                                <span
                                    className="method-input-placeholder product">Выберите продукт</span>
                                <i className="fa fa-chevron-down"></i>
                            </div>
                            <div className="method-options-block2">
                                <ul className="method-options-list">
                                    {products.map((item) => (
                                        <li key={item.id} className="method-options-item"
                                            onClick={(event) => {
                                                selectProduct(event, item)
                                            }}>{item.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <span className="method-text-error input3"></span>
                        <div className="checkbox-block">
                            <div className="checkbox-block-row">
                                <input type="checkbox" className="checkbox" checked={checkbox}
                                       onChange={(event) => setCheckbox(event.target.checked)}/>
                                <span className="checkbox-text">Я принимаю <Link to="#" className="invite-friend-link">условия активации</Link> торгового советника.</span>
                            </div>
                            <span className="method-text-error checkbox-error"></span>
                        </div>
                        <button className="method-of-replenishment-btn"
                                onClick={(event) => activate_account(event)}>Активировать
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExpertsActivation;