import React, {useEffect, useState} from "react";
import "./ExpertsProduct.css"
import {Link, useParams} from "react-router-dom";
import Notification from "../../../components/Notification/Notification";
import BaseScreen from "./BaseScreen/BaseScreen";
import StatisticsScreen from "./StatisticsScreen/StatisticsScreen";
import ReplacingAccountNumberScreen from "./ReplacingAccountNumberScreen/ReplacingAccountNumberScreen";
import ReplacingSystemScreen from "./ReplacingSystemScreen/ReplacingSystemScreen";
import SubscriptionRenewalScreen from "./SubscriptionRenewalScreen/SubscriptionRenewalScreen";
import axios from "axios";

const ExpertsProduct = ({setLocation, url}) => {
    const [selectedLink, setSelectedLink] = useState("base");
    const [account, setAccount] = useState(null);
    const { account_number } = useParams();

    const active_button = (event) => {
        const listBtns = document.querySelectorAll(".my-trading-system-nav-btn");

        listBtns.forEach((btn) => {
            btn.classList.remove("active");
        });

        event.target.classList.add("active");
    }

    useEffect(() => {
        setLocation("experts-product");
    }, [setLocation]);

    useEffect(() => {
        const get_product = async () => {
            try {
                if (account_number) {
                    const response = await axios.get(`${url}/account/get-account?account_number=${account_number}`, {withCredentials: true});
                    setAccount(response.data);
                }
            } catch (error_response) {
                console.error('Error fetching product data', error_response);
            }
        }
        get_product()
    }, [account_number, url]);

    return (
        <>
            <section className="experts-product">
                <div className="experts-product-column">
                    <Notification/>
                    <Link to="/experts" className="experts-product-back-btn">
                        <i className="fa fa-chevron-left"></i>
                        назад
                    </Link>
                    <div className="my-trading-system">
                        <div className="my-trading-system-header">
                            <h2 className="my-trading-system-title">Моя торговая система</h2>
                            <h3 className="my-trading-system-subtitle">Детализация и управление счётом</h3>
                        </div>
                        <div className="my-trading-system-screens">
                            <ul className="my-trading-system-nav-list">
                                <li className="my-trading-system-nav-item">
                                    <button className="my-trading-system-nav-btn active"
                                            onClick={(event) => {
                                                active_button(event);
                                                setSelectedLink("base");
                                            }}>Общее
                                    </button>
                                </li>
                                <li className="my-trading-system-nav-item">
                                    <button className="my-trading-system-nav-btn "
                                            onClick={(event) => {
                                                active_button(event);
                                                setSelectedLink("statistics");
                                            }}>Статистика
                                    </button>
                                </li>
                                <li className="my-trading-system-nav-item">
                                    <button className="my-trading-system-nav-btn "
                                            onClick={(event) => {
                                                active_button(event);
                                                setSelectedLink("replacing the account number");
                                            }}>Замена номера
                                    </button>
                                </li>
                                <li className="my-trading-system-nav-item">
                                    <button className="my-trading-system-nav-btn "
                                            onClick={(event) => {
                                                active_button(event);
                                                setSelectedLink("replacing the system");
                                            }}>Замена системы
                                    </button>
                                </li>
                                <li className="my-trading-system-nav-item">
                                    <button className="my-trading-system-nav-btn "
                                            onClick={(event) => {
                                                active_button(event);
                                                setSelectedLink("subscription renewal");
                                            }}>Продление подписки
                                    </button>
                                </li>
                            </ul>
                            {selectedLink === "base" ? (
                                <BaseScreen url={url} account={account}/>
                            ) : selectedLink === "statistics" ? (
                                <StatisticsScreen url={url} account={account}/>
                            ) : selectedLink === "replacing the account number" ? (
                                <ReplacingAccountNumberScreen url={url} account={account}/>
                            ) : selectedLink === "replacing the system" ? (
                                <ReplacingSystemScreen url={url} account={account}/>
                            ) : selectedLink === "subscription renewal" && (
                                <SubscriptionRenewalScreen url={url} account={account}/>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExpertsProduct